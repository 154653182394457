import React, {useEffect, useState} from "react";
import apiManager from "../../api/apiManager";
import { Form, Row, Col, Select, Input, Button } from "antd";
import { SwapRightOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import "./reasignresources.css";

export default function ReasignResources (props) {

  console.log(props)
  const [resourcesList, setResourcesList] = useState([])
  const [formReasign] = Form.useForm()
  const actualResource = props.data.resource_id

  useEffect(() => {
    apiManager.getResourcesBooking(
      props.data.turn_id,
      props.data.cat_act_id,
      JSON.parse(localStorage.getItem("activeCenter")).id,
      dayjs(props.data.start).format("DD-MM-YYYY"),
      1,
      0
    )
    .then((res) => {
      console.log(res.data.data)
      let result = res.data.data.resources
      setResourcesList([])
      result.map((resource) => {
        setResourcesList(current => [...current, {
          'label': resource.title,
          'value': resource.id
        }])
      })
    })
  }, [])

  const reasignResource = (values) => {
    //console.log(actualResource)
    let data = {
      day: dayjs(props.data.start).format("DD-MM-YYYY"),
      turn: props.data.turn_id,
      activity: props.data.cat_act_id,
      center: JSON.parse(localStorage.getItem("activeCenter")).id,
      resource_occupation_id: props.data.event_id,
      resource_id: values.newResource
    }
    apiManager.reasignResource(data)
    .then((res) => {
      console.log(res.data.data)
      props.closeReasign()
    })
  }

  return (
    <>
      <Form
        form={formReasign}
        layout="vertical"
        className="reasignResourceForm"
        onFinish={() => reasignResource(formReasign.getFieldsValue())}
      >
        <Row justify="space-between" style={{alignItems: "flex-end", marginTop: 20}}>
          <Col span={24}>
            <h6
              style={{fontSize: 16, fontWeight: 600, marginBottom: 20}}
            >Reasignar recurso de la actividad <span className="primaryColor">{ props.data.title }</span> de las <span className="primaryColor">{ dayjs(props.data.start).format("HH:mm") }</span>h</h6>
          </Col>
          <Col span={11}>
            <Form.Item
              label="Recurso actual"
              name="oldResource"
            >
              <Input defaultValue={props.data.resource_title} disabled={true} />
            </Form.Item>
          </Col>
          <Col span={2} style={{display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 34}}>
            <SwapRightOutlined />
          </Col>
          <Col span={11}>
            <Form.Item
              label="Nuevo recurso"
              name="newResource"
              rules={[
                {
                  required: true,
                  message: "Este campo es obligatorio"
                },
              ]}
            >
              <Select
                placeholder="Selecciona un nuevo recurso"
                options={resourcesList}
              />
            </Form.Item>
          </Col>
          <Col span={24} style={{marginTop: 10}}>
            <Form.Item
              style={{marginBottom: 0, display: "flex", justifyContent: "center"}}
            >
              <Button
                className="basic-btn"
                onClick={() => props.closeReasign()}
              >Cancelar</Button>
              <Button
                className="main-btn"
                style={{marginLeft: 20}}
                htmlType="submit"
              >Reasignar</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}