import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Divider, Form, Input, Collapse, Tag, Modal, DatePicker, InputNumber, Select, notification, Typography, Badge, Checkbox, Tooltip } from "antd";
import { FormOutlined, DeleteOutlined, ReadOutlined, SnippetsOutlined, EditOutlined, CheckOutlined, CloseOutlined, FilePdfFilled, ContainerFilled, PlusOutlined, DeleteFilled, SendOutlined, TagsFilled, BookFilled, ConsoleSqlOutlined } from '@ant-design/icons';
import ActivityForm from "../Actividades/ActivityForm";
import BookingToPrint from "./BookingToPrint";
import { Percent, Description, Person, LockOpen, Lock, Print } from "@mui/icons-material";
import ReactToPrint from "react-to-print";

import { useUser } from "../../api/context/userContext";

import apiManager from "../../api/apiManager";
import dayjs from 'dayjs';
import moment from "moment";
import "./bookinginfo.css";
import Loader from "../Loader/Loader";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function BookingInfo(props) {

  const { userData } = useUser()
  const [infoBooking, setInfoBooking] = useState([])
  const [personalDataInfo, setPersonalDataInfo] = useState({})
  const [dataClientInfo, setDataClientInfo] = useState({})
  const [statusPayment, setStatusPayment] = useState("")
  const [isModalAddPayment, setIsModalAddPayment] = useState(false)
  const [isModalPaymentHistoric, setIsModalPaymentHistoric] = useState(false)
  const [isModalAnnotation, setIsModalAnnotation] = useState(false)
  const [isModalServices, setIsModalServices] = useState(false)
  const [isModalNewActivity, setIsModalNewActivity] = useState(false)
  const [isModalEditActivity, setIsModalEditActivity] = useState(false)
  const [isModalHistoricBooking, setIsModalHistoricBooking] = useState(false)
  const [isModalPrintPDF, setIsModalPrintPDF] = useState(false)
  const [optionsPayment, setOptionsPayment] = useState([])
  const [optionTypes, setOptionsType] = useState([])
  const [serviceEdit, setServiceEdit] = useState({edit: false, id: null})
  const [paymentOperator, setPaymentOperator] = useState('add')
  const [notesList, setNotesList] = useState([])
  const [aditionalServicesList, setAditionalServicesList] = useState([])
  const [activitiesList, setActivitiesList] = useState([])
  const [totalPaid, setTotalPaid] = useState(0)
  const [editPd, setEditPd] = useState(false)
  const [editDc, setEditDc] = useState(false)
  //const [newActivityDate, setNewActivityDate] = useState(props.selectedDate)
  const [newActivityDate, setNewActivityDate] = useState(null)
  const [editActivityDate, setEditActivityDate] = useState(props.selectedDate)
  const [paymentEdit, setPaymentEdit] = useState(null)
  const [invoice, setInvoice] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [invoiceDisabled, setInvoiceDisabled] = useState(false)
  const [dataEdit, setDataEdit] = useState({})
  const [editActivityId,setEditActivityId] = useState(null)
  const [isModalInvoice, setIsModalInvoice] = useState(false)
  const [invoiceData, setInvoiceData] = useState({})
  const [ownerB2B, setOwnerB2B] = useState(false)
  const [onDateB2B, setOnDateB2B] = useState(false)
  const [editablePermissons, setEditablePermissons] = useState(true)

  const { TextArea } = Input
  const { Panel } = Collapse
  const { Option } = Select
  const { Text } = Typography

  const [form] = Form.useForm()
  const [formDc] = Form.useForm()
  const [formPayment] = Form.useForm()
  const [formNotes] = Form.useForm()
  const [formServices] = Form.useForm()
  const [formFac] = Form.useForm()

  let componentRef = useRef()

  useEffect(() => {
    loadInfoBooking(props.bookingInfoData.id)
  }, [])

  const loadInfoBooking = (bookingId) => {
    //setIsLoading(true)
    apiManager.getBookingInfo(bookingId)
    .then((res) => {
      let data = res.data.data
      if (userData.is_b2b) {
        let today = new Date()
        today.setHours(0,0,0,0)
        let startDateFrag = data[0].start_date.split("-")
        let startDate = new Date(`${startDateFrag[1]}/${startDateFrag[0]}/${startDateFrag[2]}`)
        startDate.setHours(0,0,0,0)
        let diff = startDate.getTime() - today.getTime()
        let dayDiff = diff / (1000 * 60 * 60 * 24)
        setOwnerB2B(
          userData.user_token === data[0].creator_user_token?
            true 
          : false
        )
        setOnDateB2B(
          dayDiff > parseInt(userData.edition_period) ?
            true
          : false
        )
      }
      setInfoBooking(data)
      setPersonalDataInfo({
        name: data[0].client_info.name,
        surname: data[0].client_info.surname,
        dni: data[0].client_info.dni,
        phone: data[0].client_info.phone,
        email: data[0].client_info.email,
      })
      if (data[0].client_info.social_reason !== undefined) {
        setInvoiceData({
          socialReason: data[0].client_info.social_reason,
          fiscalEmail: data[0].client_info.fiscal_email,
          fiscalNif: data[0].client_info.fiscal_nif,
          fiscalAddress: data[0].client_info.fiscal_address,
          postalCode: data[0].client_info.cp,
          city: data[0].client_info.city,
          country: data[0].client_info.country
        })
      }
      setDataClientInfo({
        refInt: data[0].internal_ref !== undefined ? data[0].internal_ref : ""
      })
      let statusBilling = data[0].status_billing.toLowerCase()
      let statusLower = data[0].status.toLowerCase()
      if (statusLower === "no pagado") {
        setStatusPayment("unpaid")
      } else if (statusLower === "parcial") {
        setStatusPayment("parcial")
      } else if (statusLower === "pagado") {
        setStatusPayment("paid")
      } else if (statusLower === "a devolver") {
        setStatusPayment("return")
      } else if (statusLower === "cancelada") {
        setStatusPayment("canceled")
      } else if (statusLower === "bloqueada") {
        setStatusPayment("bloqued")
      }
      if (statusBilling === "albaran" || statusBilling === "realbaranear") {
        if (!userData.roles.includes("ROLE_ADMIN_FACT")) {
          setEditablePermissons(false)
        }
      } else if (statusBilling === "facturada") {
        setEditablePermissons(false)
      }
      setOptionsPayment([])
      apiManager.getPaymentMethods(data[0].sales_channel_info.id, data[0].center_info.id)
      .then((res) => {
        res.data.data.map((type) => {
          setOptionsPayment(current => [...current, {
            'value': type.id,
            'label': type.title
          }])
        })
      })
      setNotesList(data[0].observations)
      setAditionalServicesList(data[0].aditional_services)
      setActivitiesList(data[0].booking_activities)
      setInvoice(data[0].client_invoice)
      //console.log(data[0].client_invoice)
      //console.log(data[0].aditional_services)
      //console.log(data[0].booking_activities)
      setOptionsType([])
      apiManager.getServicesTypes(JSON.parse(localStorage.getItem("activeCenter")).id)
      .then((res) => {
        res.data.data.map((type) => {
          setOptionsType(current => [...current, {
            'value': type.id,
            'label': type.title
          }])
        })
      })
      //setIsLoading(false)
    })
  }

  const editPersonalData = () => {
    setEditPd(true)
  }

  const editDataClient = () => {
    setEditDc(true)
  }

  const cancelEditPersonalData = () => {
    form.setFieldsValue(personalDataInfo)
    setEditPd(false)
  }

  const cancelEditDataClient = () => {
    formDc.setFieldsValue(dataClientInfo)
    setEditDc(false)
  }

  const savePersonalData = () => {
    let nameValue = form.getFieldValue('name').trim()
    let surnameValue = form.getFieldValue('surname').trim()
    if (nameValue === "" || surnameValue === "") {
      notification.error({
        key: 'warning',
        placement: 'bottom',
        duration: 0,
        message: `Los campos Nombre y Apellidos no pueden estar vacios`
      })
    } else {
      setIsLoading(true)
      if (JSON.stringify(personalDataInfo) !== JSON.stringify(form.getFieldsValue())) {
        let newData = {
          booking_id: props.bookingInfoData.id,
          client_name: form.getFieldValue('name'),
          client_surname: form.getFieldValue('surname'),
          client_phone: form.getFieldValue('phone'),
          client_mail: form.getFieldValue('email'),
          client_dni: form.getFieldValue('dni')
        }
        apiManager.editClient(newData)
        .then((res) => {
          setPersonalDataInfo({
            name: form.getFieldValue('name'),
            surname: form.getFieldValue('surname'),
            dni: form.getFieldValue('dni'),
            phone: form.getFieldValue('phone'),
            email: form.getFieldValue('email'),
          })
          loadInfoBooking(props.bookingInfoData.id)
          setEditPd(false)
          setIsLoading(false)
        })
      } else {
        setEditPd(false)
        setIsLoading(false)
      }
    }
  }

  const saveDataClient = () => {
    setIsLoading(true)
    if (JSON.stringify(dataClientInfo) !== JSON.stringify(formDc.getFieldsValue())) {
      let refIntValue = formDc.getFieldValue('refInt')
      let newData = {
        booking_id: props.bookingInfoData.id,
        internal_ref: refIntValue === "" ? "vacioRR" : refIntValue,
      }
      apiManager.editDataClient(newData)
      .then((res) => {
        setDataClientInfo({
          internal_ref: formDc.getFieldValue('refInt'),
        })
        loadInfoBooking(props.bookingInfoData.id)
        setEditDc(false)
        setIsLoading(false)
      })
    } else {
      setEditDc(false)
      setIsLoading(false)
    }
  }

  const descargarPdf = (reservaId) => {
    let urlToGo= process.env.REACT_APP_FHIOS_API_URL.replace('api/','')+"obtain-bono/"+reservaId
    window.open(urlToGo);
  }

  const confirmSuprimir = (reservaInfo) => {
    if (reservaInfo.status === "No pagado" || (reservaInfo.status === "Pagado" && reservaInfo.final_price === "0")|| (reservaInfo.status === "A crédito" && reservaInfo.final_price == reservaInfo.pending_price)) {
      confirmAlert({
        title: 'Suprimir reserva',
        message: '¿Seguro que quieres suprimir la reserva ' + reservaInfo.identifier + '?',
        buttons: [
          {
            label: 'Confirmar',
            onClick: () => deleteReseva(reservaInfo.id),
            className: 'confirm-btn',
          },
          {
            label: 'Cancelar',
            className: 'cancel-btn',
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: "overlay-confirm-suprimir"
      })
    }else{
      confirmAlert({
        title: 'Suprimir reserva',
        message: 'No se puede suprimir una reserva sin devolver el pago al cliente.',
        buttons: [
          {
            label: 'Cancelar',
            className: 'cancel-btn',
          }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        overlayClassName: "overlay-confirm-suprimir"
      })
    }
  }

  const updateBloqued = (bookingId, status) => {
    setIsLoading(true)
    let statusBloqued = status !== "Bloqueada" ? true : false
    apiManager.updateStatusBloqued(bookingId, statusBloqued)
    .then((res) => {
      loadInfoBooking(props.bookingInfoData.id)
      setIsLoading(false)
    })
  }

  const deleteReseva = (reservaId) => {
    let data = {
      booking_id: reservaId
    }
    apiManager.deleteBooking(data)
    .then((res) => {
      console.log(res)
      props.bookingClose()
    })
  };

  const paymentChange = (changed, formValues) => {
    if (formValues.method !== undefined && (formValues.amount !== 0 && formValues.amount !== null)) {
      setTotalPaid(formValues.amount)
    } else {
      setTotalPaid(0)
    }
  }

  const closeAddPaymentModal = () => {
    formPayment.resetFields()
    setPaymentOperator('add')
    setPaymentEdit(null)
    setIsModalAddPayment(false)
  }

  const makePaiment = () => {
    if ((formPayment.getFieldValue('method') === undefined || formPayment.getFieldValue('method') === null) || formPayment.getFieldValue('amount') === null) {
      formPayment.validateFields()
    } else if (formPayment.getFieldValue('amount') === 0) {
      console.log(formPayment.getFieldsValue())
      notification.error({
        key: 'warning',
        placement: 'bottom',
        duration: 0,
        message: `El pago/devolución no puede ser 0`
      })
    } else {
      let amountValue = 0
      if (paymentOperator === 'add') {
        amountValue = formPayment.getFieldValue('amount')
      } else {
        amountValue = -Math.abs(formPayment.getFieldValue('amount'))
      }
      let dataValue = {
        booking_id: props.bookingInfoData.id,
        user_token: localStorage.getItem("token"),
        method_payment_id: formPayment.getFieldValue('method'),
        payment_done: amountValue,
        payment_date: dayjs(formPayment.getFieldValue('date')).format('YYYY-MM-DD')
      }
      apiManager.createPayment(dataValue)
      .then((res) => {
        console.log(res.data.data)
        loadInfoBooking(props.bookingInfoData.id)
        formPayment.resetFields()
        setPaymentOperator('add')
        setIsModalAddPayment(false)
      })
    }
  }

  const changeOperator = (value) => {
    setPaymentOperator(value)
  }

  const selectBefore = (
    <Select
      value={paymentOperator}
      style={{
        width: 50,
      }}
      onChange={(e) => changeOperator(e)}
    >
      <Option value="add">+</Option>
      <Option value="minus">-</Option>
    </Select>
  );

  const closeAnnotationModal = () => {
    setIsModalAnnotation(false)
  }

  const deleteAnotacion = (id, index) => {
    /* var newListado = [...notesList];
    newListado.splice(index, 1);
    setNotesList(newListado); */
    apiManager.deleteObservation(id)
    .then((res) => {
      console.log(res.data.data)
      loadInfoBooking(props.bookingInfoData.id)
    })
  }

  const addNote = (values) => {
    console.log(values)
    let dateComment = moment(Date.now()).format('DD/MM/YYYY - H:mm') + 'h'
    apiManager.createObservation(localStorage.getItem("token"), props.bookingInfoData.id, dateComment, values.note)
    .then((res) => {
      console.log(res.data.data)
      formNotes.resetFields()
      loadInfoBooking(props.bookingInfoData.id)
    })
  }

  const createAditionalService = (values) => {
    let data = {
      booking_id: props.bookingInfoData.id,
      description: values.description,
      price: values.price,
      type: values.type
    }
    apiManager.createAditionalService(data)
    .then((res) => {
      console.log(res)
      formServices.resetFields()
      loadInfoBooking(props.bookingInfoData.id)
    })
  }

  const deleteAditionalService = (id) => {
    let data = {
      aditional_service_id: id,
      booking_id: props.bookingInfoData.id
    }
    apiManager.deleteAditionalService(data)
    .then((res) => {
      console.log(res)
      resetFormServices()
      loadInfoBooking(props.bookingInfoData.id)
    })
  }

  const editAditionalService = (values) => {
    let data = {
      booking_aditional_service_id: serviceEdit.id,
      booking_id: props.bookingInfoData.id,
      description: values.description,
      price: values.price,
      type: values.type
    }
    apiManager.editAditionalService(data)
    .then((res) => {
      console.log(res.data.data)
      formServices.resetFields()
      setServiceEdit({edit: false, id: null})
      loadInfoBooking(props.bookingInfoData.id)
    })
  }

  const prepareEditService = (id) => {
    setServiceEdit({edit: true, id: id})
    formServices.setFieldValue('type', aditionalServicesList.find(o => o.id === id).type)
    formServices.setFieldValue('description', aditionalServicesList.find(o => o.id === id).description)
    formServices.setFieldValue('price', aditionalServicesList.find(o => o.id === id).price)
  }

  const resetFormServices = () => {
    formServices.resetFields()
    setServiceEdit({edit: false, id: null})
  }

  const closeServicesModal = () => {
    formServices.resetFields()
    setIsModalServices(false)
    //setServiceEdit({edit: false, index: -1})
  }

  const confirmRemoveService = (id) => {
    confirmAlert({
      title: '¿Quieres eliminar este servicio?',
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => deleteAditionalService(id),
          className: 'confirm-btn',
        },
        {
          label: 'Cancelar',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const confirmDeleteActivity = (id, name) => {
    confirmAlert({
      title: `¿Quieres eliminar la actividad ${name}?`,
      buttons: [
        {
          label: 'Confirmar',
          onClick: () => deleteActivity(id),
          className: 'confirm-btn',
        },
        {
          label: 'Cancelar',
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const deleteActivity = (id) => {
    let data = {
      booking_activity_id: id,
      booking_id: props.bookingInfoData.id
    }
    apiManager.deleteActivity(data)
    .then((res) => {
      console.log(res.data.data)
      loadInfoBooking(props.bookingInfoData.id)
    })
  }

  const closeNewActivityModal = () => {
    setNewActivityDate(null)
    setIsModalNewActivity(false)
  }

  const saveActivity = (activity) => {    
    console.log(activity)
    let newActivity = {
      booking_id: props.bookingInfoData.id,
      booking_activities: [
        {
          id: activity.formValues.activity,
          start_date: newActivityDate,
          end_date: newActivityDate,
          tarifas: activity.finishRates.map((rate) => {
            return {
              tarifa_id: rate.resumeRate.value,
              occupation: rate.units,
              price: rate.ratePrice / rate.units,
              promotion: rate.discountType,
              base_price: rate.basePrice,
            }
          }),
          num_pax: activity.persons,
          price_booking: activity.finishRates.reduce((accumulator, object) => {return accumulator + object.ratePrice;}, 0),
          turn_id: activity.formValues.turns,
          resource_occupation: activity.formValues.resources !== undefined ? activity.formValues.resources.map((resource) => {
            return {
              id: activity.byUnitMode ? resource : resource.value,
              resource_id: activity.byUnitMode ? resource : resource.value,
              occupation: activity.byUnitMode ? "1" : resource.inputValue.toString(),
              remove: false,
            }
          })
          : []
        }
      ]
    }
    apiManager.createActivity(newActivity)
    .then((res) => {
      console.log(res.data.data)
      loadInfoBooking(props.bookingInfoData.id)
      setIsModalNewActivity(false)
    })
  }

  const editPayment = (paymentData) => {
    //console.log(paymentData)
    formPayment.setFieldValue('method', optionsPayment.find(({label}) => label === paymentData.payment_method).value)
    formPayment.setFieldValue('amount', Math.abs(paymentData.payment_done))
    formPayment.setFieldValue('date', dayjs(paymentData.payment_date.split("/").reverse().join("-")))
    setPaymentEdit(paymentData.id)
    setPaymentOperator(paymentData.payment_done < 0 ? 'minus' : 'add')
    setIsModalAddPayment(true)
  }

  const updatePayment = () => {
    console.log(formPayment.getFieldsValue())
    if ((formPayment.getFieldValue('method') === undefined || formPayment.getFieldValue('method') === null) || formPayment.getFieldValue('amount') === null) {
      formPayment.validateFields()
    } else if (formPayment.getFieldValue('amount') === 0) {
      notification.error({
        key: 'warning',
        placement: 'bottom',
        duration: 0,
        message: `El pago/devolución no puede ser 0`
      })
    } else {
      let amountValue = 0
      if (paymentOperator === 'add') {
        amountValue = formPayment.getFieldValue('amount')
      } else {
        amountValue = -Math.abs(formPayment.getFieldValue('amount'))
      }
      let dataValue = {
        booking_id: props.bookingInfoData.id,
        booking_payment_id: paymentEdit,
        user_token: localStorage.getItem("token"),
        method_payment_id: formPayment.getFieldValue('method'),
        payment_done: amountValue,
        payment_date: dayjs(formPayment.getFieldValue('date')).format('YYYY-MM-DD')
      }
      apiManager.editPayment(dataValue)
      .then((res) => {
        console.log(res.data.data)
        loadInfoBooking(props.bookingInfoData.id)
        formPayment.resetFields()
        setPaymentEdit(null)
        setPaymentOperator('add')
        setIsModalAddPayment(false)
      })
    }
  }

  const historicBooking = () => {

  }

  const invoiceChange = (value, checked) => {
    //console.log(value)
    if (checked !== null) {
      if (checked) {
        setInvoice(checked)
        //setIsModalInvoice(true)
        let data = {
          booking_id: props.bookingInfoData.id,
          status: checked,
        }
        apiManager.updateClientInvoice(data)
        .then((res) => {
          //console.log(res.data.data)
          //setIsModalInvoice(false)
          loadInfoBooking(props.bookingInfoData.id)
        })
      } else {
        let data = {
          booking_id: props.bookingInfoData.id,
          status: checked,
          status_data: true,
          client_social_reason: value.socialReason,
          client_fiscal_email: value.fiscalEmail,
          client_fiscal_nif: value.fiscalNif,
          client_fiscal_address: value.fiscalAddress,
          client_cp: value.postalCode,
          client_city: value.city,
          client_country: value.country
        }
        apiManager.updateClientInvoice(data)
        .then((res) => {
          //console.log(res.data.data)
          setIsModalInvoice(false)
          loadInfoBooking(props.bookingInfoData.id)
        })
      }
    } else {
      if (JSON.stringify(value) !== JSON.stringify(invoiceData) || invoice !== infoBooking[0].client_invoice) {
        let data = {
          booking_id: props.bookingInfoData.id,
          status: invoice,
          status_data: true,
          client_social_reason: value.socialReason,
          client_fiscal_email: value.fiscalEmail,
          client_fiscal_nif: value.fiscalNif,
          client_fiscal_address: value.fiscalAddress,
          client_cp: value.postalCode,
          client_city: value.city,
          client_country: value.country
        }
        apiManager.updateClientInvoice(data)
        .then((res) => {
          //console.log(res.data.data)
          setIsModalInvoice(false)
          loadInfoBooking(props.bookingInfoData.id)
        })
      } else {
        setIsModalInvoice(false)
      }
    }
  }

  const closeEditActivityModal = () => {
    setIsModalEditActivity(false)
    setEditActivityId(null)
  }

  const openEditActiity = (info) => {
    console.log(info)
    setEditActivityId(info.id)
    setEditActivityDate(info.start_date)
    setIsLoading(true)
    let actFamId = 0
    let optionFamilies = []
    let optionsActivities = []
    let optionsRates = []
    let optionsTurns = []
    let activitiesArray = []
    let maxPersons = 0
    let optionResources = []
    let resourcesIds = []
    let finishRates = []
    let byUnitActivity = true
    let maxUnits = 0
    apiManager.getTarifasListSimple(props.bookingInfoData.channel_id, JSON.parse(localStorage.getItem("activeCenter")).id, userData.is_b2b, userData.user_token)
    .then((res) => {
      //console.log(res.data.data)
      let activitiesList = res.data.data.sort(function(a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      var getTurnsOptions = new Promise(function(resolve, reject) {
        //apiManager.reloadTurns(JSON.parse(localStorage.getItem("activeCenter")).id, info.category_activity_id, info.start_date, 1, 1, userData.is_b2b ? userData.is_b2b : undefined)
        apiManager.reloadTurnsSimple(JSON.parse(localStorage.getItem("activeCenter")).id, info.category_activity_id, info.start_date, userData.is_b2b ? userData.is_b2b : undefined)
        .then((res) => {
          let turns = res.data.data
          turns.map((item) => {
            optionsTurns.push({
              'value': item.id,
              'label': item.schedule
            })
          })
          if (optionsTurns.some(e => e.value === info.turns_info[0].id)) {
            //No hacer nada
          } else {
            optionsTurns.push({
              'value': info.turns_info[0].id,
              'label': info.turns_info[0].schedule
            })
          }
          resolve('done')
        })
      })
      var getFamilyOptions = new Promise(function(resolve2, reject) {
        activitiesList.map((activity, index) => {
          optionFamilies.push({
            'value': activity.id,
            'label': activity.name
          })
          if (activity.children.some(item => item.id === info.category_activity_id)) {
            activitiesArray = activity.children
            actFamId = activity.id
            let activityChosen = activity.children.find(item => item.id === info.category_activity_id)
            byUnitActivity = activityChosen.by_unit
            maxPersons = activityChosen.max_pax_into_activity
            activityChosen.children.map((rate) => {
              optionsRates.push({
                'label': rate.name + " - " + (!userData.is_b2b ? rate.price : rate.pvp_price) + "€",
                'name': rate.name,
                'need_resources': rate.need_resources,
                'pax': rate.max_pax,
                'price': rate.price,
                'pvp_price': rate.pvp_price,
                'ratio_resources': rate.ratio_resources,
                'value': rate.id
              })
            })
            /* activityChosen.turns.map((item) => {
              optionsTurns.push({
                'value': item.id,
                'label': item.schedule
              })
            })
            if (optionsTurns.some(e => e.value === info.turns_info[0].id)) {
              //No hacer nada
            } else {
              optionsTurns.push({
                'value': info.turns_info[0].id,
                'label': info.turns_info[0].schedule
              })
            } */
            activity.children.map((item) => {
              optionsActivities.push({
                'value': item.id,
                'label': item.name
              })
            })
            info.resources_info.map((resource, index) => {
              //console.log(resource)
              if (resource.by_unit) {
                resourcesIds.push(resource.id)
              } else {
                resourcesIds.push({
                  'value': resource.id,
                  'label': resource.title,
                  'num_available': resource.num_available + info.resource_occupations.find(e => e.resource_id === resource.id).occupation,
                  'by_unit': resource.by_unit,
                  'inputValue': info.resource_occupations.find(e => e.resource_id === resource.id).occupation,
                })
              }
              optionResources.push({
                'value': resource.id,
                'label': resource.title,
                'num_available': resource.num_available + info.resource_occupations.find(e => e.resource_id === resource.id).occupation,
                'by_unit': resource.by_unit,
                'disabled': true,
                'text': resource.title.split(':').shift(),
                'has_occupation': resource.has_occupation
              })
            })
            apiManager.getResourcesBooking(
              info.turns_info[0].id,
              info.category_activity_id,
              JSON.parse(localStorage.getItem("activeCenter")).id,
              info.start_date,
              info.num_pax,
              typeof resourcesIds[0] === "object" ? 1 : info.resources_info.length,
              undefined,
              'edit'
            )
            .then(async(res) => {
              let resources = res.data.data.resources
              maxUnits = res.data.data.maxUnits
              for (let resource of resources) {
                //console.log(resource)
                if (optionResources.some(x => x.value === resource.id)) {
                  let indexResource = optionResources.findIndex(x => x.value === resource.id)
                  optionResources[indexResource].num_available = optionResources[indexResource].num_available + resource.num_available
                } else {
                  optionResources.push({
                    'value': resource.id,
                    'label': resource.title,
                    'num_available': resource.num_available,
                    'by_unit': resource.by_unit,
                    'text': resource.text,
                    'has_occupation': resource.has_occupation
                  })
                }
              }
              resolve2('done')
              /* resources.map((resource) => {
                console.log(resource)
                if (optionResources.some(x => x.value === resource.id)) {
                  let indexResource = optionResources.findIndex(x => x.value === resource.id)
                  optionResources[indexResource].num_available = optionResources[indexResource].num_available + resource.num_available
                } else {
                  optionResources.push({
                    'value': resource.id,
                    'label': resource.title,
                    'num_available': resource.num_available,
                    'by_unit': resource.by_unit,
                    'text': resource.text,
                    'has_occupation': resource.has_occupation
                  })
                }
              }) */
            })
            info.booking_activity_occupations.map((occupation) => {
              finishRates.push({
                basePrice: occupation.base_price !== undefined ? occupation.base_price : undefined,
                discountType: occupation.promotion !== undefined ? occupation.promotion : undefined,
                modifyRate: {
                  operator: "minus",
                  value: occupation.base_price !== undefined && occupation.base_price !== occupation.price ? occupation.base_price - (occupation.base_price - occupation.price) : 0,
                  symbol: "sum"
                },
                paxValue: parseInt(optionsRates.find(item => item.name === occupation.title).pax) * occupation.occupation,
                ratioResources: optionsRates.find(item => item.name === occupation.title).ratio_resources * occupation.occupation,
                resumeRate: optionsRates.find(item => item.name === occupation.title),
                ratePrice: occupation.price * occupation.occupation,
                ratePricePvP: occupation.pvp_price * occupation.occupation,
                units: occupation.occupation,
                pvp_price: occupation.pvp_price
              })
            })
          } else {
            if (index === activitiesList.length - 1) {
              //resolve2('done')
            }
          }
        })
        //resolve2('done')
      })
      getTurnsOptions.then(function(value) {
        getFamilyOptions.then(function(value) {
          //console.log(typeof resourcesIds[0] === "object")
          /* let byUnitMode = true
          if (resourcesIds.length > 0 && typeof resourcesIds[0] === "object") {
            byUnitMode = false
            console.log("byUnit false")
          } else {
            byUnitMode = true
            console.log("byUnit true")
          } */
          let dataEdit = {
            activitiesArray: activitiesArray,
            editRateIndex: -1,
            editRateSelect: [],
            familiesArray: activitiesList,
            finishRates: finishRates,
            formValues: {
              activity: info.category_activity_id,
              activityFamily: actFamId,
              resources: resourcesIds,
              turns: info.turns_info[0].id
            },
            indexActivity: undefined,
            maxPersons: maxPersons,
            modifyRate: {operator: "minus", value: 0, symbol: "sum"},
            optionsActivities: optionsActivities,
            optionsFamilies: optionFamilies,
            optionsRates: optionsRates,
            optionsResources: optionResources.sort((a,b) => a.value - b.value),
            optionsTruns: optionsTurns.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase())),
            persons: info.num_pax,
            rateModal: false,
            ratePrice: 0,
            ratePricePvP: 0,
            resumeRate: {},
            selectedResources: resourcesIds,
            selectedTurn: info.turns_info[0].id,
            units: 1,
            dateStart: info.start_date,
            //byUnitMode: typeof resourcesIds[0] === "object" ? false : true,
            byUnitMode: byUnitActivity,
            maxUnits: maxUnits,
            bookingId: info.id
          }
          setDataEdit(dataEdit)
          //console.log(dataEdit)
          setIsModalEditActivity(true)
          setIsLoading(false)
        })
      })
    })
    //console.log(info)
  }

  const saveEditActivity = (a) => {
    //console.log(a)
    setIsLoading(true)
    let data = {
      id: editActivityId,
      tarifas: a.finishRates.map((rate) => {
        return {
          tarifa_id: rate.resumeRate.value,
          occupation: rate.units,
          price: rate.ratePrice / rate.units,
          promotion: rate.discountType,
          base_price: rate.basePrice !== undefined ? rate.basePrice : rate.ratePrice / rate.units,
        }
      }),
      num_pax: a.persons,
      price_booking: a.finishRates.reduce((accumulator, object) => {return accumulator + object.ratePrice;}, 0),
      turn_id: a.formValues.turns,
      resource_occupation: a.formValues.resources.map((resource) => {
        return {
          id: a.byUnitMode ? resource : resource.value,
          resource_id: a.byUnitMode ? resource : resource.value,
          occupation: a.byUnitMode ? "1" : resource.inputValue.toString(),
          remove: false,
        }
      }),
    }
    apiManager.editBookingActivity(data)
    .then((res) => {
      let result = res.data.data
      setEditActivityId(null)
      setIsModalEditActivity(false)
      setIsLoading(false)
      loadInfoBooking(props.bookingInfoData.id)
    })
    console.log(data)
  }

  const cancelChangesActivity = (a) => {
    setIsModalEditActivity(false)
  }

  const cancelInvoice = () => {
    if (Object.keys(invoiceData).length > 0) {
      formFac.setFieldValue("socialReason", invoiceData.socialReason)
      formFac.setFieldValue("fiscalEmail", invoiceData.fiscalEmail)
      formFac.setFieldValue("fiscalNif", invoiceData.fiscalNif)
      formFac.setFieldValue("fiscalAddress", invoiceData.fiscalAddress)
      formFac.setFieldValue("postalCode", invoiceData.postalCode)
      formFac.setFieldValue("city", invoiceData.city)
      formFac.setFieldValue("country", invoiceData.country)
      if (!infoBooking[0].client_invoice) {
        setInvoice(false)
      }
      setIsModalInvoice(false)
    } else {
      formFac.setFieldValue("socialReason", undefined)
      formFac.setFieldValue("fiscalEmail", undefined)
      formFac.setFieldValue("fiscalNif", undefined)
      formFac.setFieldValue("fiscalAddress", undefined)
      formFac.setFieldValue("postalCode", undefined)
      formFac.setFieldValue("city", undefined)
      formFac.setFieldValue("country", undefined)
      //setInvoice(false)
      setIsModalInvoice(false)
      /* notification.warning({
        key: 'warning',
        placement: 'bottom',
        duration: 2,
        message: `Se ha cancelado la factura para cliente`
      }) */
    }
    setIsModalInvoice(false)
  }

  class DocToPrint extends React.Component {
    render() {
      return (
        <>
          <BookingToPrint data={infoBooking[0]} referencia={componentRef} />
        </>
      )
    }
  }

  const headerPanelActivity = (info, index) => (
    
    <Row>
      <Col span={24} style={{display: "flex", justifyContent: "space-between", marginBottom: 2, gap: 50}}>
        <h4>{ info.category_activity_name_bono }</h4><h4 style={{whiteSpace: "nowrap"}}>Total: <b>{ (!userData.is_b2b ? info.total_price : info.pvp_price).toFixed(2).replace(/[.,]00$/, "") }€</b> { info.booking_activity_occupations.filter(e => e.promotion_label !== undefined).length > 0 ? <Tooltip title="Actividad con descuento aplicado"><Percent className="iconPromotionAct" /></Tooltip> : ""}</h4>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={19}>
          <p><b>Fecha</b>: { info.start_date } | <b>Turno</b>: { info.turns_info[0].schedule } | <b>Personas</b>: { info.num_pax } | <b>Tarifas</b>: {info.booking_activity_occupations.length} | <b>Recursos</b>: { info.resources_info.length }</p>
          </Col>
          <Col span={5}>
            <Row onClick={event => event.stopPropagation()} justify="end" style={{gap: 5}}>
              {
                userData.is_b2b ?
                  ownerB2B && onDateB2B && editablePermissons ?
                    <Button
                      className="iconCircle editBtn"
                      title="Editar actividad"
                      onClick={() => { openEditActiity(info) }}
                      //disabled={true}
                    ><FormOutlined className="white" /></Button>
                  : <></>
                : editablePermissons ?
                    <Button
                      className="iconCircle editBtn"
                      title="Editar actividad"
                      onClick={() => { openEditActiity(info) }}
                      //disabled={true}
                    ><FormOutlined className="white" /></Button>
                  : <></>
              }
              {
                !userData.is_b2b ?
                  <>
                    <Button
                      className="iconCircle historicBtn"
                      title="Historial actividad (Funcionalidad no disponible)"
                      onClick={() => { console.log('') }}
                      disabled={true}
                    ><ReadOutlined className="white" /></Button>
                    { editablePermissons ?
                      <Button
                        className="iconCircle deleteBtn"
                        title="Eliminar actividad"
                        onClick={() => { confirmDeleteActivity(info.id, info.category_activity_name) }}
                      ><DeleteOutlined className="white" /></Button>
                    : <></>
                    }
                  </>
                : <></>
              }
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )

  return (
    <>
      <Loader controller={isLoading} />
      <Modal
        footer={null}
        open={isModalPrintPDF}
        centered={true}
        maskClosable={false}
        onCancel={() => setIsModalPrintPDF(false)}
        destroyOnClose={true}
        width={1000}
        className="booking-print"
      >
        <div>
          <ReactToPrint
            trigger={() => <Tooltip title="Imprimir documento">
              <Button style={{position: 'absolute', top: 8, left: 8, height: 'auto'}}><Print style={{fontSize: 30}} /></Button>
            </Tooltip>}
            content={() => componentRef}
            documentTitle={`booking-${infoBooking[0] !== undefined ? infoBooking[0].identifier : 0}`}
          />
          <DocToPrint ref={(el) => (componentRef = el)} />
        </div>
      </Modal>
      <Modal
        footer={null}
        open={isModalInvoice}
        centered={true}
        maskClosable={false}
        onCancel={() => cancelInvoice()}
        width={600}
        destroyOnClose={true}
      >
        <Form
          form={formFac}
          onFinish={() => invoiceChange(formFac.getFieldsValue(), null)}
          style={{marginTop: 15}}
          layout="vertical"
          initialValues={invoiceData}
        >
          <Row>
            <Col span={11}>
              <Form.Item
                label="Razón social"
                name="socialReason"
                rules={
                  [{
                    required: true,
                    message: "Este campo es obligatorio",
                  }]
                }
              >
                <Input placeholder="Añade la razón social" />
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item
                label="CIF Facturación"
                name="fiscalNif"
                rules={
                  [{
                    required: true,
                    message: "Este campo es obligatorio",
                  }]
                }
              >
                <Input placeholder="Añade el NIF de facturación" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Email Facturación"
                name="fiscalEmail"
                rules={
                  [{
                    required: true,
                    message: "Este campo es obligatorio",
                  }]
                }
              >
                <Input placeholder="Añade el email de facturación" />
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item
                label="Dirección fiscal"
                name="fiscalAddress"
                rules={
                  [{
                    required: true,
                    message: "Este campo es obligatorio",
                  }]
                }
              >
                <Input placeholder="Añade la dirección fiscal" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="Código postal"
                name="postalCode"
                rules={
                  [{
                    required: true,
                    message: "Este campo es obligatorio",
                  }]
                }
              >
                <Input placeholder="Añade el código postal" />
              </Form.Item>
            </Col>
            <Col span={11} offset={2}>
              <Form.Item
                label="Ciudad"
                name="city"
                rules={
                  [{
                    required: true,
                    message: "Este campo es obligatorio",
                  }]
                }
              >
                <Input placeholder="Añade la ciudad" />
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item
                label="País"
                name="country"
                rules={
                  [{
                    required: true,
                    message: "Este campo es obligatorio",
                  }]
                }
              >
                <Input placeholder="Añade un país" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            style={{marginTop: 20}}
          >
            <Button
              className="basic-btn"
              style={{marginRight: 20}}
              onClick={() => cancelInvoice()}
            >{ (ownerB2B || !userData.is_b2b) && editablePermissons ? 'Cancelar' : 'Cerrar' }</Button>
            {
              (ownerB2B || !userData.is_b2b) && editablePermissons ?
                <Button
                  className="main-btn"
                  htmlType="submit"
                >Guardar</Button>
              : <></>
            }
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeNewActivityModal()}
        centered={true}
        open={isModalNewActivity}
        width={newActivityDate !== null ? 1140 : 500}
        destroyOnClose={true}
        footer={null}
      >
        <div style={{marginTop: 20}}>
          <Form>
            <Form.Item
              label="Fecha de la actividad:" 
              style={{
                paddingLeft: 15,
                width: newActivityDate !== null ? 'auto' : 'fit-content',
                marginInline: newActivityDate !== null ? 'unset' : 'auto'
              }}
            >
              <DatePicker
                value={newActivityDate !== null ? dayjs(newActivityDate.split("-").reverse().join("-")) : null}
                onChange={(e) => setNewActivityDate(e.format("DD-MM-YYYY"))}
                allowClear={false}
                format={'DD-MM-YYYY'}
              />
              {
                newActivityDate !== null ?
                  <Text style={{paddingLeft: 15}} type="warning">Al modificar la fecha se reiniciá el formulario inferior</Text>
                : <></>
              }
            </Form.Item>
          </Form>
          {
            newActivityDate !== null ?
              <ActivityForm
                key={`newAct-${newActivityDate}`}
                selectedDay={newActivityDate}
                selectedChannel={props.bookingInfoData.channel_id}
                dateStep={newActivityDate}
                bookingInfo={true}
                saveActivity={saveActivity}
                userData={userData}
              />
            : <></>
          }
        </div>
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeEditActivityModal()}
        centered={true}
        open={isModalEditActivity}
        width={1140}
        destroyOnClose={true}
        footer={null}
      >
        <div style={{marginTop: 20}}>
          <Row>
            <Col span={24}>
              {
                Object.keys(dataEdit).length !== 0 ?
                  <Row className="finishActivity" justify="space-between">
                    <Col span={12} style={{paddingRight: 10}}>
                      <p><b>Familia de actividad</b>: {dataEdit.familiesArray.find(o => o.id === dataEdit.formValues.activityFamily).name.split(" - ").pop()}</p>
                      <p><b>Actividad</b>: {dataEdit.activitiesArray.find(o => o.id === dataEdit.formValues.activity).name.split(' - ').splice(1).join(' - ')}</p>
                      <p><b>Personas</b>: {dataEdit.persons}</p>
                      <p><b>Fecha</b>: {dataEdit.dateStart}</p>
                      <p><b>Turno</b>: {dataEdit.optionsTruns.find(o => o.value === dataEdit.formValues.turns).label}</p>
                      {
                        !userData.is_b2b ?
                          <p className="resourcesActivity"><b>Recursos:</b><br />
                            {
                              dataEdit.formValues.resources !== undefined ?
                                dataEdit.formValues.resources.map((resource, index) =>
                                  dataEdit.byUnitMode ?
                                    <span key={`resourceItem-${index}`}>{dataEdit.optionsResources.find(o => o.value === resource).label.split(':').shift()}</span>
                                  :
                                    <span key={`resourceItem-${index}`}>{dataEdit.optionsResources.find(o => o.value === resource.value).label.split(':').shift()}
                                      <b style={{display: "inline-flex", padding: "2px 5px", marginLeft: 8, borderRadius: 5, background: "#8eb6c2", color: "#FFF"}}>{resource.inputValue}<Person style={{fill: "#FFF"}} /></b>
                                    </span>
                                )
                              : <Tag color="#53b454" className="simpleTag">Actividad sin recursos</Tag>
                            }
                          </p>
                        : <p><b>Recursos</b>: {dataEdit.formValues.resources !== undefined && dataEdit.formValues.resources.length > 0 ? dataEdit.formValues.resources.length : 'Sin recursos'}</p>
                      }
                    </Col>
                    <Col span={12} className="ratesActivity" style={{paddingInline: 10}}>
                      <p style={{width: "100%"}}><b>Tarifas</b>:</p>
                      {
                        dataEdit.finishRates.map((rate, index) =>
                          <div key={`rateItem-${index}`}>
                            <div>
                              <p><b>Tarifa</b>: {rate.resumeRate.name.split(" - ").pop()}</p>
                              <p><b>Precio tarifa</b>: {
                                userData.is_b2b ? rate.resumeRate.pvp_price + "€"
                                :
                                  rate.modifyRate.value === 0 ? `${rate.resumeRate.price}€` : (
                                    <><del style={{color: 'red', fontSize: '14px'}}>{rate.resumeRate.price}€</del> {
                                      rate.modifyRate.symbol === 'sum' ? `${rate.modifyRate.value}€` :
                                        rate.modifyRate.operator === 'add' ?
                                          `${rate.resumeRate.price + ((rate.modifyRate.value / 100) * rate.resumeRate.price)}€`
                                          : `${rate.resumeRate.price - ((rate.modifyRate.value / 100) * rate.resumeRate.price)}€`
                                  }</>
                              )}</p>
                              <p><b>Unidades</b>: {rate.units}</p>
                              <p><b>Precio total</b>: {!userData.is_b2b ? rate.ratePrice : rate.ratePricePvP}€</p>
                            </div>
                          </div>
                        )
                      }
                    </Col>
                  </Row>
                : ""
              }
            </Col>
            <Col span={24}>
              {/* <Form>
                <Form.Item label="Fecha de la actividad:" style={{paddingLeft: 15}}>
                  <DatePicker
                    value={dayjs(editActivityDate.split("-").reverse().join("-"))}
                    onChange={(e) => setEditActivityDate(e.format("DD-MM-YYYY"))}
                    allowClear={false}
                    format={'DD-MM-YYYY'}
                  />
                  <Text style={{paddingLeft: 15}} type="warning">Al modificar la fecha se reiniciá el formulario inferior</Text>
                </Form.Item>
              </Form> */}
              <ActivityForm
                key={`editAct-${editActivityDate}`}
                selectedDay={editActivityDate}
                selectedChannel={props.bookingInfoData.channel_id}
                dateStep={editActivityDate}
                bookingInfo={true}
                saveActivity={saveEditActivity}
                data={dataEdit}
                cancelChangesActivity={cancelChangesActivity}
                editMode={editActivityId}
                userData={userData}
              />
            </Col>
          </Row>
        </div>
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeServicesModal()}
        centered={true}
        open={isModalServices}
        destroyOnClose={true}
        footer={null}
      >
        {
          aditionalServicesList.length > 0 ?
            <div className="servicesList">
              {
                aditionalServicesList.map((service, index) =>
                  <div className="serviceItem" key={`aditionalServiceKey-${service.id}`}>
                    <p><b>Precio</b>: <span style={{fontSize: 20, fontWeight: 300}}>{service.price}€</span></p>
                    <p><b>Tipo</b>: { optionTypes.length > 0 ? optionTypes.find(o => o.value === service.type).label : "" }</p>
                    <p><b>Descripción</b>: {service.description}</p>
                    <div className="actionBtns">
                      {
                        !userData.is_b2b ?
                        <>
                          <Button
                            className="iconCircle editBtn"
                            title="Editar servicio"
                            onClick={() => prepareEditService(service.id)}
                          ><FormOutlined className="white" /></Button>
                          <Button
                            className="iconCircle deleteBtn"
                            title="Eliminar servicio"
                            onClick={() => confirmRemoveService(service.id)}
                          ><DeleteOutlined className="white" /></Button>
                        </>
                        : <></>
                      }
                    </div>
                  </div>
                )
              }
            </div>
          :
          ""
        }
        {
          !userData.is_b2b && editablePermissons ?
            <>
              <Divider />
              <Form
                form={formServices}
                className={serviceEdit.edit ? "modeEdit" : ""}
                name="services"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                style={{marginTop: 30}}
                onFinish={() => createAditionalService(formServices.getFieldsValue())}
              >
                <Form.Item
                  label="Tipo:"
                  name="type"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es obligatorio"
                    },
                  ]}
                >
                  <Select
                    options={optionTypes}
                    placeholder="Selecciona un tipo de servicio"
                  />
                </Form.Item>
                <Form.Item
                  label="Descripción:"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Este campo es obligatorio"
                    },
                  ]}
                >
                  <TextArea rows={4} />
                </Form.Item>
                <Form.Item
                  label="Precio:"
                  name="price"
                  initialValue={0}
                >
                  <InputNumber
                    addonAfter="€"
                    //defaultValue={0}
                    //min={0}
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{span: 24}}
                >
                  <div style={{display: "flex", justifyContent: "center", gap: 20}}>
                    {
                      serviceEdit.edit ?
                        <>
                          <Button
                            className="basic-btn"
                            onClick={() => resetFormServices()}
                          >Cancelar edición</Button>
                          <Button
                            className="main-btn"
                            //style={{display: "block", margin: "auto"}}
                            onClick={() => editAditionalService(formServices.getFieldsValue())}
                          >Editar servicio</Button>
                        </>
                      :
                        <Button
                          className="main-btn"
                          //style={{display: "block", margin: "auto"}}
                          htmlType="submit"
                        >Añadir servicio</Button>
                    }
                  </div>
                </Form.Item>
              </Form>
            </>
          : <></>
        }
      </Modal>
      <Modal
        footer={null}
        open={isModalAnnotation}
        centered={true}
        maskClosable={false}
        onCancel={() => closeAnnotationModal()}
        width={700}
        destroyOnClose={true}
      >
        {
          notesList.length > 0 ?
            <div className="listNotes">
              {
                notesList.map((note, index) =>
                  <div className="noteItem" key={`noteKey-${note.id}`}>
                    <Row align="middle">
                      <Col span={22}>
                        <p><b>Fecha anotación</b>: { note.created_date }</p>
                        <p><b>Anotación</b>: { note.comment }</p>
                      </Col>
                      <Col span={2} style={{textAlign: "right"}}>
                        {
                          !userData.is_b2b && editablePermissons ?
                            <Button
                              className="iconCircle deleteBtn"
                              title="Eliminar anotación"
                              onClick={() => deleteAnotacion(note.id, index)}
                            ><DeleteOutlined className="white" /></Button>
                          : <></>
                        }
                      </Col>
                    </Row>
                  </div>
                )
              }
            </div>
          : "" 
        }
        {
          (!userData.is_b2b || ownerB2B) && editablePermissons ?
            <Form
              form={formNotes}
              onFinish={() => addNote(formNotes.getFieldsValue())}
            >
              <Form.Item
                label="Observación"
                name="note"
                style={{marginTop: 30}}
                rules={[
                  {
                    required: true,
                    message: "Este campo es obligatorio"
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>
              <Form.Item style={{display: "flex", justifyContent: "center"}}>
                <Button
                  className="basic-btn"
                  style={{marginRight: 20}}
                  onClick={() => closeAnnotationModal()}
                >Cerrar</Button>
                <Button
                  className="main-btn"
                  htmlType="submit"
                >Añadir anotación</Button>
              </Form.Item>
            </Form>
          : <></>
        }
      </Modal>
      <Modal
        className="paymentListModal"
        onCancel={() => setIsModalPaymentHistoric(false)}
        centered={true}
        open={isModalPaymentHistoric}
        destroyOnClose={true}
        cancelText="Cerrar"
        footer={null}
      >
        {
          infoBooking[0] !== undefined && infoBooking[0].list_payments.length > 0 ?
            <Row justify="space-around" style={{gap: 20, marginTop: 30}}>
              {
                infoBooking[0].list_payments.map((payment, index) =>
                  <Col span={11} className={`paymentItem ${payment.payment_done < 0 ? "return" : "paid"}`} key={`paymentKey-${index}`}>
                    {/* <p><b>Fecha { payment.payment_done < 0 ? "de la devolución" : "del pago" }</b>: {payment.payment_date}</p>
                    <p><b>Método de pago</b>: {payment.payment_method}</p> */}
                    <div style={{paddingRight: 8}}>
                      <p><b>Fecha</b>: {payment.payment_date}</p>
                      <p><b>Método</b>: {payment.payment_method}</p>
                      <p><b>Cantidad { payment.payment_done < 0 ? "devuelta" : "pagada" }</b>: {payment.payment_done < 0 ? Math.abs(payment.payment_done) : payment.payment_done}€</p>
                      <p><b>Porcentaje { payment.payment_done < 0 ? "devuelto" : "pagado" }</b>: {payment.payment_done < 0 ? Math.abs(payment.percentage) : payment.percentage}%</p>
                    </div>
                    <div style={{position: "absolute", top: 0, bottom: 0, right: -16, display: "flex", alignItems: "center"}}>
                      {
                        !userData.is_b2b && editablePermissons ?
                          <Button
                            className="iconCircle editBtn"
                            title="Editar Pago"
                            onClick={() => editPayment(payment)}
                          ><FormOutlined className="white" /></Button>
                        : <></>
                      }
                    </div>
                  </Col>
                )
              }
            </Row>
          : ""
        }
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => closeAddPaymentModal()}
        centered={true}
        open={isModalAddPayment}
        destroyOnClose={true}
        cancelText="Cerrar"
        footer={null}
      >
        <Form
          form={formPayment}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 14 }}
          style={{marginTop: 30}}
          onValuesChange={(a, e) => paymentChange(a, e)}
          initialValues={{
            method: optionsPayment.length === 1 ? optionsPayment[0].value : null,
          }}
        >
          <Form.Item
            label="Método:"
            name="method"
            rules={[
              {
                required: true,
                message: "Este campo es obligatorio"
              },
            ]}
          >
            <Select
              options={optionsPayment}
              //defaultValue={optionsPayment.length === 1 ? optionsPayment[0].value : ""}
              disabled={optionsPayment.length === 1 ? true : false}
              placeholder="Selecciona un método de pago"
            />
          </Form.Item>
          <Form.Item
            label={paymentOperator === 'add' ? "Pago" : "Devolución"}
            name="amount"
            initialValue={0}
            rules={[
              {
                required: true,
                message: "Este campo es obligatorio"
              },
            ]}
          >
            <InputNumber
              addonBefore={infoBooking[0] !== undefined && infoBooking[0].status !== "No pagado" ? selectBefore : ""}
              addonAfter="€"
              min={0}
            />
          </Form.Item>
          <Form.Item
            label={`Fecha de ${ paymentOperator === 'add' ? "pago" : "devolución" }`}
            name="date"
            initialValue={dayjs()}
          >
            <DatePicker
              allowClear={false}
              format={'DD-MM-YYYY'}
            />
          </Form.Item>
        </Form>
        <Row>
          <Col span={24} style={{display: "flex", justifyContent: "center", gap: 20}}>
            <Button className="basic-btn" onClick={() => closeAddPaymentModal()}>Cancelar</Button>
            <Button className="main-btn" onClick={() => paymentEdit === null ? makePaiment() : updatePayment()}>{ paymentEdit === null ? "Realizar" : "Editar" } { paymentOperator === 'add' ? "pago" : "devolución" }</Button>
          </Col>
        </Row>
      </Modal>
      <Modal
        maskClosable={false}
        onCancel={() => setIsModalHistoricBooking(false)}
        centered={true}
        open={isModalHistoricBooking}
        destroyOnClose={true}
        width={1000}
        cancelText="Cerrar"
        footer={null}
      >
        <Row className="historicBookingList" justify="center" style={{gap: 20}}>
          {
            infoBooking[0] !== undefined ?
              infoBooking[0].modifications.map((modification) => 
                <Col key={`modification-${modification.id}`} span={11} className="historicBookingItem">
                  <p><b>Usuario</b>: { modification.user }</p>
                  <p><b>Fecha modificación</b>: { modification.date }</p>
                  <p><b>Modificación</b>:<br />{ modification.comment }</p>
                </Col>
              )
            :
            ""
          }
        </Row>
      </Modal>
      {
        infoBooking[0] !== undefined ?
        <Row className="bookingModalRow" justify="space-between">
          <Col span={24} className="bookingTitle" style={{display: "flex", alignItems: "center", gap: 10}}>
            Reserva { infoBooking[0].identifier }
            {
              infoBooking[0].modifications.length > 0 && !userData.is_b2b ?
                <Button
                  className="iconCircle historicBtn"
                  title="Historial cambios reserva"
                  onClick={() => setIsModalHistoricBooking(true)}
                ><ReadOutlined className="white" /></Button>
              : ""
            }
            {
              userData.is_b2b ?
                ownerB2B && onDateB2B && editablePermissons ?
                  <Button
                    className="iconCircle deleteBtn"
                    title="Eliminar reserva"
                    onClick={() => confirmSuprimir(infoBooking[0])}
                  ><DeleteOutlined className="white" /></Button>
                : <></>
              :
                editablePermissons ?
                  <>
                    <Button
                      className="iconCircle deleteBtn"
                      title="Eliminar reserva"
                      onClick={() => confirmSuprimir(infoBooking[0])}
                      disabled={infoBooking[0].status.toLowerCase() === "cancelada"}
                    ><DeleteOutlined className="white" /></Button>
                    {
                      infoBooking[0].status !== "Bloqueada" ?
                        <Button
                          className="iconCircle lockBtn"
                          title="Bloquear reserva"
                          style={{backgroundColor: '#acacac'}}
                          onClick={() => updateBloqued(infoBooking[0].id, infoBooking[0].status)}
                        ><LockOpen style={{fill: '#FFF'}} /></Button>
                      :
                        <Button
                          className="iconCircle lockBtn"
                          title="Desbloquear reserva"
                          onClick={() => updateBloqued(infoBooking[0].id, infoBooking[0].status)}
                        ><Lock style={{fill: '#FFF'}} /></Button>
                    }
                  </>
                : <></>
            }
          </Col>
          <Divider style={{marginTop: 15}} />
          <Col span={8} className="bookingInfo">
            <Row className="infoHeader">
              <Col span={14}>
                <h4>Datos reserva</h4>
              </Col>
              <Col span={10} style={{display: "flex", justifyContent: "flex-end", gap: 5}}>
                {
                  (!userData.is_b2b || (ownerB2B && onDateB2B)) && editablePermissons ?
                    editDc ?
                      <>
                        <Button
                          className="iconCircle confirmBtn"
                          title="Aceptar cambios"
                          onClick={() => saveDataClient()}
                        ><CheckOutlined className="white" /></Button>
                        <Button
                          className="iconCircle deleteBtn"
                          title="Cancelar cambios"
                          onClick={() => cancelEditDataClient()}
                        ><CloseOutlined className="white" /></Button>
                      </>
                    :
                      <Button
                        className="iconCircle"
                        title="Editar datos"
                        onClick={() => editDataClient()}
                      ><EditOutlined className="white" /></Button>
                  : <></>
                }
              </Col>
            </Row>
            <Form form={formDc} size="small" className="personalDataForm" initialValues={dataClientInfo}>
              <Form.Item label="Canal">
                <TextArea value={infoBooking[0].sales_channel_info.title} bordered={false} autoSize={{minRows: 1}} disabled={true} />
              </Form.Item>
              <Form.Item label="Identificador">
                <TextArea value={infoBooking[0].identifier} bordered={false} autoSize={{minRows: 1}} disabled={true} />
              </Form.Item>
              <Form.Item label="Fecha creación">
                <TextArea value={infoBooking[0].created_date} bordered={false} autoSize={{minRows: 1}} disabled={true} />
              </Form.Item>
              <Form.Item label="Fecha entrada">
                <TextArea value={infoBooking[0].start_date} bordered={false} autoSize={{minRows: 1}} disabled={true} />
              </Form.Item>
              <Form.Item label="Fecha salida">
                <TextArea value={infoBooking[0].end_date} bordered={false} autoSize={{minRows: 1}} disabled={true} />
              </Form.Item>
              <Form.Item label="Ref. interna" name="refInt">
                <TextArea bordered={false} autoSize={{minRows: 1}} disabled={editDc ? false : true}  />
              </Form.Item>
            </Form>
          </Col>
          <Col span={8} className="personalDataInfo">
            <Row className="infoHeader">
              <Col span={14}>
                <h4>Datos cliente</h4>
              </Col>
              <Col span={10} style={{display: "flex", justifyContent: "flex-end", gap: 5}}>
                {
                  (!userData.is_b2b || (ownerB2B && onDateB2B)) && editablePermissons ?
                    editPd ?
                      <>
                        <Button
                          className="iconCircle confirmBtn"
                          title="Aceptar cambios"
                          onClick={() => savePersonalData()}
                        ><CheckOutlined className="white" /></Button>
                        <Button
                          className="iconCircle deleteBtn"
                          title="Cancelar cambios"
                          onClick={() => cancelEditPersonalData()}
                        ><CloseOutlined className="white" /></Button>
                      </>
                    :
                      <Button
                        className="iconCircle"
                        title="Editar datos"
                        onClick={() => editPersonalData()}
                      ><EditOutlined className="white" /></Button>
                  : <></>
                }
              </Col>
            </Row>
            <Form form={form} className="personalDataForm" size="small" initialValues={personalDataInfo}>
              <Form.Item label="Nombre" name="name">
                <TextArea bordered={false} autoSize={{minRows: 1}} disabled={editPd ? false : true} />
              </Form.Item>
              <Form.Item label="Apellidos" name="surname">
                <TextArea bordered={false} autoSize={{minRows: 1}} disabled={editPd ? false : true}  />
              </Form.Item>
              <Form.Item label="DNI" name="dni">
                <TextArea bordered={false} autoSize={{minRows: 1}} disabled={editPd ? false : true}  />
              </Form.Item>
              <Form.Item label="Teléfono" name="phone">
                <TextArea bordered={false} autoSize={{minRows: 1}} disabled={editPd ? false : true}  />
              </Form.Item>
              <Form.Item label="Email" name="email">
                <TextArea bordered={false} autoSize={{minRows: 1}} disabled={editPd ? false : true}  />
              </Form.Item>
            </Form>
          </Col>
          <Col span={6} className={!userData.is_b2b ? `paymentInfo ${statusPayment}` : ""}>
            {
              !userData.is_b2b ?
                <>
                  <Row className="infoHeader">
                    <Col span={20}>
                      <h4>Resumen pagos</h4>
                    </Col>
                    <Col span={4} style={{display: "flex", justifyContent: "flex-end"}}>
                      {
                        infoBooking[0].list_payments.length > 0 ?
                          <Button
                            className="iconCircle historicBtn"
                            title="Historial de pagos"
                            onClick={() => setIsModalPaymentHistoric(true)}
                          ><ReadOutlined className="white" /></Button>
                        : ""
                      }
                    </Col>
                  </Row>
                  <Row justify="space-between" style={{height: "100%"}}>
                    <Col span={24}>
                      <p className="statusPayment"><b>Estado del pago</b>: { infoBooking[0].status }</p>
                      <p><b>Precio total</b>: { Number(infoBooking[0].final_price).toFixed(2).replace(/[.,]00$/, "") }€</p>
                      <p><b>Pagos realizados</b>: { Number(infoBooking[0].payment_done).toFixed(2).replace(/[.,]00$/, "") }€</p>
                    </Col>
                    <Col span={24}>
                      <p className={`pending ${statusPayment}`}>Pendiente: <span>{ Number(infoBooking[0].pending_price).toFixed(2).replace(/[.,]00$/, "") }€</span></p>
                    </Col>
                  </Row>
                </>
              : <></>
            }
          </Col>
          <Col span={15} className="activitiesList">
            {
              infoBooking[0].booking_activities.map((activity, index) =>
                <Collapse className="activityItemCol" key={`collapse-act-${index}`}>
                  <Panel header={ headerPanelActivity(activity, index) } key={`panel-act-${index}`}>
                    <Row style={{paddingLeft: 24}} justify="space-between" className="activityItemColDetail">
                      <Col span={24}>
                        <p className="title"><b>Tarifas</b>:</p>
                        {
                          activity.booking_activity_occupations.map((rate, index) =>
                            !userData.is_b2b ?
                              <p key={`rate-info-${index}`}>{ rate.title.split(" - ").pop() } x { rate.occupation } | Precio tarifa: { rate.promotion === undefined ? (rate.price).toFixed(2).replace(/[.,]00$/, "") : <Tooltip title={`Descuento: ${rate.promotion_label.split('-').at(-1)}`}><del style={{color: 'red'}}>{rate.base_price}€</del> {(rate.price).toFixed(2).replace(/[.,]00$/, "")}</Tooltip> }€ | Precio total: <b>{ (rate.price * rate.occupation).toFixed(2).replace(/[.,]00$/, "") }€</b></p>
                            : 
                              <p key={`rate-info-${index}`}>{ rate.title.split(" - ").pop() } x { rate.occupation } | Precio tarifa: { (rate.pvp_price).toFixed(2).replace(/[.,]00$/, "")}€ | Precio total: <b>{ (rate.pvp_price * rate.occupation).toFixed(2).replace(/[.,]00$/, "") }€</b></p>
                          )
                        }
                      </Col>
                      {
                        !userData.is_b2b ?
                          <Col span={24} style={{marginTop: 10}}>
                            <p className="title"><b>Recursos</b>:</p>
                            {
                              activity.resources_info.length > 0 ?
                                activity.resources_info.map((resource, index) =>
                                  resource.by_unit ?
                                    <Tag key={`tag-reso-${index}`} color="blue" style={{marginBottom: 5}}>{ resource.title.split(":").shift() }</Tag>
                                  :
                                    <Tag key={`tag-reso-${index}`} color="blue" style={{marginBottom: 5}}>{ resource.title.split(":").shift() }
                                      <b style={{display: "inline-flex", padding: "2px 5px", marginLeft: 8, marginRight: "-7px", background: "#93a9b9", color: "#FFF"}}>{activity.resource_occupations.find(e => e.resource_id === resource.id).occupation}<Person style={{fill: "#FFF"}} /></b>
                                    </Tag>
                                )
                              :
                                <Tag color="green">Sin recursos</Tag>
                            }
                          </Col>
                        : <></>
                      }
                    </Row>
                  </Panel>
                </Collapse>
              )
            }
            {
              !userData.is_b2b && editablePermissons ?
                <div style={{display: "flex", justifyContent: "center"}}>
                  <Button
                    style={infoBooking[0].booking_activities.length > 0 ? {marginTop: 10} : {marginTop: 0}}
                    className="iconCircle mainBtn"
                    title="Añadir actividad"
                    onClick={() => setIsModalNewActivity(true)}
                  ><PlusOutlined className="white" /></Button>
                  {/* <Button className="main-btn" onClick={() => setIsModalNewActivity(true)}>Añadir actividad</Button> */}
                </div>
              : <></>
            }
          </Col>
          <Col span={8} className="totalBooking">
            {
              !userData.is_b2b && editablePermissons ?
                <Button
                  className="main-btn"
                  onClick={() => setIsModalAddPayment(true)}
                  //disabled={!ownerB2B}
                  //style={!ownerB2B ? {visibility: 'hidden'} : {}}
                >Añadir pago/devolución</Button>
              : <></>
            }
            <div className="totalPrices" style={{marginTop: 20}}>
              <h4>Total actividades: <span>{ Number(activitiesList.reduce((accumulator, object) => {return !userData.is_b2b ? accumulator + object.total_price : accumulator + object.pvp_price;}, 0)).toFixed(2).replace(/[.,]00$/, "") }€</span></h4>
              <h4>Servicios adicionales: <span>{ aditionalServicesList.reduce((accumulator, object) => {return accumulator + object.price;}, 0) }€</span></h4>
              <h3>Total reserva: <b>{ Number(!userData.is_b2b ? infoBooking[0].final_price : infoBooking[0].pvp_price).toFixed(2).replace(/[.,]00$/, "") }€</b></h3>
              {
                !userData.is_b2b ?
                  <div style={{
                    display: "flex",
                    alignItems: "flex-start",
                    flexDirection: 'column',
                    marginTop: 20,
                    gap: 12
                  }}>
                    <Checkbox
                      style={{display: "flex", justifyContent: "flex-end"}}
                      checked={invoice}
                      onChange={(e) => invoiceChange(formFac.getFieldsValue(), e.target.checked)}
                      disabled={invoiceDisabled || !editablePermissons}
                    >Factura para cliente</Checkbox>
                    <Button
                      disabled={!invoice}
                      style={{display: 'flex', gap: 5, paddingInline: '5px 8px', marginLeft: '-2px'}}
                      onClick={() => {setIsModalInvoice(true)}}
                    ><Description /> Datos de facturación</Button>
                  </div>
                : <></>
              }
            </div>
          </Col>
          <Divider />
          <Col span={24} style={{display: "flex", justifyContent: "center", gap: 20}}>
            <Badge count={notesList.length}>
              <Button
                className="main-btn"
                icon={<ContainerFilled />}
                onClick={() => setIsModalAnnotation(true)}
                disabled={((!userData.is_b2b || ownerB2B) && editablePermissons) || ((userData.is_b2b || !editablePermissons) && notesList.length > 0) ? false : true}
              >Anotaciones</Button>
            </Badge>
            <Badge count={aditionalServicesList.length}>
              <Button
                className="main-btn"
                icon={<TagsFilled />}
                onClick={() => setIsModalServices(true)}
                disabled={(userData.is_b2b || !editablePermissons) && aditionalServicesList.length === 0}
              >Servicios adicionales</Button>
            </Badge>
            {/* <Button className="main-btn" icon={<FilePdfFilled />} onClick={() => descargarPdf(props.bookingInfoData.id)}>Descargar PDF</Button> */}
            <Button className="main-btn" icon={<FilePdfFilled />} onClick={() => setIsModalPrintPDF(true)}>Descargar PDF</Button>
            {/* <Button className="main-btn" icon={<DeleteFilled />} onClick={() => confirmSuprimir(infoBooking[0])}>Eliminar reserva</Button> */}
          </Col>
          <Divider />
          <Col span={24} style={{display: "flex", justifyContent: "flex-end"}}>
            <Button className="basic-btn" onClick={() => props.bookingClose()}>Cerrar reserva</Button>
          </Col>
        </Row>
        : ""
      }
    </>
  )
}