import React, {useEffect, useState, useRef} from "react";
import { Dropdown, Tooltip, Empty, Table, Badge, Select, Row, Col, Button, Input, Form } from "antd";
import { MoreOutlined, EllipsisOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { PriorityHigh, Person, CheckCircleTwoTone, CancelTwoTone, Refresh } from '@mui/icons-material';
import dayjs from "dayjs";

import "./schedulertable.css";

export default function SchedulerTable (props) {

  //console.log(props)

  const tableRef = useRef()
  const [form] = Form.useForm()
  let dataArray = []
  let arrayTime = []

  useEffect(() => {
    const element = document.querySelector('.scrollTableTop')
    element.style.width = tableRef.current.scrollWidth.toString() + "px"
  }, [])

  if (props.familySelected !== null) {
    form.setFieldValue('activity', props.familySelected)
  } else {
    form.setFieldValue('activity', null)
  }

  if (props.textFilter !== null) {
    form.setFieldValue('client', props.textFilter)
  } else {
    form.setFieldValue('client', null)
  }

  for (let i = 8; i < 24; i++) {
    let arrayMinutes = []
    let minutes = 0
    let hour = i.toString()
    if (hour.length < 2) {
      hour = "0" + hour
    }
    for (let e = 0; e < 12; e++) {
      arrayMinutes.push({
        title: minutes !== 15 && minutes !== 30 && minutes !== 45 ? "" : <span>{minutes.toString()}</span>,
        dataIndex: hour + ":" + (minutes.toString().length > 1 ? minutes.toString() : `0${minutes.toString()}`),
        key: hour + ":" + (minutes.toString().length > 1 ? minutes.toString() : `0${minutes.toString()}`),
        className: "minute-cell"
      })
      minutes = minutes + 5
    }
    arrayTime.push(
      {
        title: hour + ":00",
        width: 200,
        children: arrayMinutes
      }
    )
  }
  let eventsData = props.data.events
  let resourcesData = props.data.resources
  let rowsEvents = []
  if (eventsData.length > 0) {
    resourcesData.map((resource) => {
      if (eventsData.some(e => e.assignee === resource.assignee)) {
        rowsEvents.push(eventsData.filter(item => item.assignee === resource.assignee))
      } else {
        rowsEvents.push([{
          assignee: resource.assignee,
          by_unit: true,
          is_vip: false,
          client_arrived: false,
          client_checking: false,
          over_booking: false,
          start: dayjs().hour(6),
          end: dayjs().hour(7),
          title: '',
          turn_id: 0,
          client_info: '',
          cat_act_id: 0,
          resource_title: resource.text,
          empty_resource: true,
          tarifas_info: []
        }])
      }
    })
  }

  arrayTime.unshift({
    title: "Recursos",
    dataIndex: 'resource',
    key: 'resource',
    filters: [{text: 'Con actividad', value: 'con'}, {text: 'Sin actividad', value: 'sin'}],
    defaultFilteredValue: ['con'],
    onFilter: (value, record) => record.empty.indexOf(value) === 0,
  })

  const scrollMaster = (e) => {
    let element = e.target
    let other = e.target.parentNode.querySelector('.syncScrollsTop')
    other.scroll({
      left: element.scrollLeft
    })
  }

  const scrollSlave = (e) => {
    let element = e.target
    let other = e.target.parentNode.querySelector('.syncScrollsTable')
    other.scroll({
      left: element.scrollLeft
    })
  }

  const text_truncate = (str, length, ending, durationItem) => {
    if (durationItem > 1) {
      if (length === null || durationItem > 8) {
        length = str.length
      } else if (durationItem === 2) {
        length = 16
      }
      if (ending == null) {
        ending = '...'
      }
      if (str.length > length) {
        let text = str.substring(0, length - ending.length)
        return text
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ') + ending
      } else {
        return str
          .toLowerCase()
          .split(' ')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
      }
    } else {
      let nameSplit = str.split(" ")
      nameSplit = nameSplit.map((item) => item[0]).join('')
      return nameSplit.toUpperCase()
    }
  }

  const duration = (start, end) => {
    let diff = Math.abs(start - end)
    let totalMinutes = Math.floor((diff/1000)/60)
    let calcDuration = totalMinutes / 5
    return calcDuration
  }

  const durationFormat = (start, end) => {
    var diff = Math.abs(start - end);
    var totalMinutes = Math.floor((diff/1000)/60);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if (hours > 0) {
      if (minutes > 0) {
        return hours.toString() + "h " + minutes.toString() + "m"
      } else {
        return hours.toString() + "h"
      }
    } else {
      return minutes.toString() + "m"
    }
  }

  const limiteText = (text, duration, mode) => {
    let labelValue = ''
    if (text !== undefined) {
      labelValue = text
    }
    //let family = props.families.some(e => e.id === text) ? props.families.find(e => e.id === text).text : ''
    //family = family.split('-').shift().trim()
    if (duration > 1 && labelValue.length <= 9 && labelValue.length > 0) {
      return mode === 'tooltip' ? null : labelValue
    } else if(duration > 2 && labelValue.length <= 12 && labelValue.length > 0) {
      return mode === 'tooltip' ? null : labelValue
    } else if(duration > 4 && labelValue.length > 0) {
      return mode === 'tooltip' ? null : labelValue
    } else if(labelValue.length > 0) {
      return mode === 'tooltip' ? labelValue : null
    } else {
      return null
    }
  }

  const ratesFormat = (values) => {
    let result = ""
    for (let [i, rate] of values.entries()) {
      result += "<b>Tarifa " + (Number(i) + 1) + "</b>: " + rate + "<br />"
    }
    return result
  }

  const contructEvent = (event, durationItem, multi) => {
    //console.log(event.tarifas_info)
    if (!multi) {
      let bgColor = event.client_arrived ? "#63A647" : event.client_checking ? "#e2b46d" : "#FF4D4F"
      return (
        <Tooltip placement="top" title={<p style={{textAlign: "center"}}>
          { event.title } - { durationFormat(event.start, event.end) } <ClockCircleOutlined className="black" /><br />
          { event.tarifas_info.length > 0 ?
            <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: ratesFormat(event.tarifas_info)}}></div>
            : ""
          }
        </p>} color="#FFF">
          <Dropdown
            menu={{
              items: [
                {
                  label: <div onClick={() => props.openBookingModal(event)}>Abrir reserva</div>,
                  key: '0',
                },
                {
                  type: 'divider',
                },
                {
                  label: <div onClick={() => props.openReasignResources(event)}>Reasignar recursos</div>,
                  key: '1',
                },
                {
                  type: 'divider',
                },
                props.centerAdmin && event.by_unit ? {
                  label: <div onClick={() => props.openOverbookingResources(event)}>Reubicar reserva</div>,
                  key: '2',
                } : "",
                props.centerAdmin && event.by_unit ? {
                  type: 'divider',
                } : "",
                {
                  label:
                    <div style={{display: "flex", gap: 5}} onClick={() => props.clientArrivedFunc(event.booking_activity_id, !event.client_arrived)}>
                      <div style={{position: 'absolute', left: -12, display: 'flex', backgroundColor: '#FFF', borderRadius: '50%'}}>
                        { event.client_arrived ? <CheckCircleTwoTone style={{fill: '#63A647'}} /> : <CancelTwoTone style={{fill: "#ff4d4f"}} /> }
                      </div> Cliente en actividad
                    </div>,
                  key: '3',
                },
                !event.client_arrived ? {
                  type: 'divider',
                } : "",
                !event.client_arrived ? {
                  label:
                    <div style={{display: "flex", gap: 5}} onClick={() => props.clientCheckingFunc(event.booking_activity_id, !event.client_checking)}>
                      <div style={{position: 'absolute', left: -12, display: 'flex', backgroundColor: '#FFF', borderRadius: '50%'}}>
                        { event.client_checking ? <CheckCircleTwoTone style={{fill: '#63A647'}} /> : <CancelTwoTone style={{fill: "#ff4d4f"}}  /> }
                      </div> Cheking cliente
                    </div>,
                  key: 4
                } : ""
              ]
            }}
            trigger={['click']}
          >
            <div
              className={`schedulerEvent${ event.over_booking ? " overbooking" : "" }${ (!event.by_unit && event.by_unit !== undefined) && durationItem < 5 ? " pax" : "" }`}
              //onClick={(e) => console.log(event)}
              style={{
                minWidth: `calc(${100 * durationItem}% + ${1 * durationItem}px - 1px)`,
                backgroundColor: bgColor
              }}
            >
              { event.over_booking ? <a title="Overbooking" className="badge-event badge-overbooking"></a> : null }
              { event.need_payment ? <a title="Pendiente pago" className="badge-event badge-price"></a> : null }
              { event.exist_anottation ? <a title="Anotaciones" className="badge-event badge-notes"></a> : null }
              { event.is_vip ? <a title="VIP" className="badge-event badge-vip"></a> : null }
              <div style={{color: '#FFF', textAlign: 'center'}}>
                { text_truncate(event.client_info, 28, null, durationItem, event.by_unit) }
                {
                  durationItem > 0 ?
                    limiteText(event.cat_act_label, durationItem) !== null ?
                      <><br />{ limiteText(event.cat_act_label, durationItem) + " - " + event.tarifas_info.join(" / ") }</>
                    : <></>
                  : <></>
                }
                {
                  !event.by_unit ?
                    <p className={`paxValues${event.client_arrived ? " arrived" : event.client_checking ? " checking" : ""}`}><Person style={{fill: '#FFF'}} />{ `${event.num_pax}/${event.num_pax_total}` }</p>
                  : ""
                }
              </div>
            </div>
          </Dropdown>
        </Tooltip>
      )
    } else {
      return (
        <>
          {
            event.map((item, index) => (
              <Tooltip placement="left" title={<p style={{textAlign: "center"}}>
                { item.title } - { durationFormat(item.start, item.end) } <ClockCircleOutlined className="black" /><br />
                { item.tarifas_info.length > 0 ?
                  <div style={{textAlign: 'left'}} dangerouslySetInnerHTML={{__html: ratesFormat(item.tarifas_info)}}></div>
                  : ""
                }
              </p>} color="#FFF">
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: <div onClick={() => props.openBookingModal(item)}>Abrir reserva</div>,
                        key: '0',
                      },
                      {
                        type: 'divider',
                      },
                      {
                        label: <div onClick={() => props.openReasignResources(item)}>Reasignar recursos</div>,
                        key: '1',
                      },
                      {
                        type: 'divider',
                      },
                      props.centerAdmin && item.by_unit ? {
                        label: <div onClick={() => props.openOverbookingResources(item)}>Reubicar reserva</div>,
                        key: '2',
                      } : "",
                      props.centerAdmin && item.by_unit ? {
                        type: 'divider',
                      } : "",
                      {
                        label:
                          <div style={{display: "flex", gap: 5}} onClick={() => props.clientArrivedFunc(item.booking_activity_id, !item.client_arrived)}>
                            <div style={{position: 'absolute', left: -12, display: 'flex', backgroundColor: '#FFF', borderRadius: '50%'}}>
                              { item.client_arrived ? <CheckCircleTwoTone style={{fill: '#63A647'}} /> : <CancelTwoTone style={{fill: "#ff4d4f"}} /> }
                            </div> Cliente en actividad
                          </div>,
                        key: '3',
                      },
                      !item.client_arrived ? {
                        type: 'divider',
                      } : "",
                      !item.client_arrived ? {
                        label:
                          <div style={{display: "flex", gap: 5}} onClick={() => props.clientCheckingFunc(item.booking_activity_id, !item.client_checking)}>
                            <div style={{position: 'absolute', left: -12, display: 'flex', backgroundColor: '#FFF', borderRadius: '50%'}}>
                              { item.client_checking ? <CheckCircleTwoTone style={{fill: '#63A647'}} /> : <CancelTwoTone style={{fill: "#ff4d4f"}}  /> }
                            </div> Cheking cliente
                          </div>,
                        key: 4
                      } : ""
                    ]
                  }}
                  trigger={['click']}
                >
                  <div
                    className={`schedulerEvent${ item.over_booking ? " overbooking" : "" }${ (!item.by_unit && item.by_unit !== undefined) && durationItem < 5 ? " pax" : "" }`}
                    //onClick={(e) => console.log(item)}
                    style={{
                      minWidth: `calc(${100 * durationItem[index]}% + ${1 * durationItem[index]}px - 1px)`,
                      backgroundColor: item.client_arrived ? "#63A647" : item.client_checking ? "#e2b46d" : "#FF4D4F",
                      top: `${(index * 50) + 30}px`,
                      borderTop: `${index !== 0 ? "1px solid #FFF" : "none"}`
                    }}
                  >
                    { item.over_booking ? <a title="Overbooking" className="badge-event badge-overbooking"></a> : null }
                    { item.need_payment ? <a title="Pendiente pago" className="badge-event badge-price"></a> : null }
                    { item.exist_anottation ? <a title="Anotaciones" className="badge-event badge-notes"></a> : null }
                    { item.is_vip ? <a title="VIP" className="badge-event badge-vip"></a> : null }
                    <div style={{color: '#FFF', textAlign: 'center'}}>
                      { text_truncate(item.client_info, 28, null, durationItem[index], item.by_unit) }
                      {
                        durationItem[index] > 0 ?
                          limiteText(item.cat_act_label, durationItem[index]) !== null ?
                            <><br />{ limiteText(item.cat_act_label, durationItem[index]) + " - " + item.tarifas_info.join(" / ") }</>
                          : <></>
                        : <></>
                      }
                      {
                        !item.by_unit ?
                          <p className={`paxValues${item.client_arrived ? " arrived" : item.client_checking ? " checking" : ""}`}><Person style={{fill: '#FFF'}} />{ `${item.num_pax}/${item.num_pax_total}` }</p>
                        : ""
                      }
                    </div>
                  </div>
                </Dropdown>
              </Tooltip>
            ))
          }
          <p style={{height: `${(event.length - 1) * 50}px`}}></p>
        </>
      )
    }
  }

  const prepareData = () => {
    //console.log(rowsEvents)
    for (const event of rowsEvents) {
      if (event.length > 0) {
        let overbooking = false
        if (event.some(e => e.over_booking === true)) {
          overbooking = true
        }
        let resourceName = !overbooking ? event[0].empty_resource ? <b className="emptyResource">{ event[0].resource_title }</b> : event[0].resource_title : <Tooltip title={<>{"Recurso con"}<br />{"overbooking"}</>}><b className="resourceOverbooking">{event[0].resource_title}</b></Tooltip>
        let object = { resource: resourceName, empty: event[0].empty_resource ? 'sin' : 'con' }
        if (event.length > 1) {
          var groupedEvents = event.reduce((x, y) => {
            (x[y.start] = x[y.start] || []).push(y)
            return x
          }, {})
          for (const event of Object.values(groupedEvents)) {
            if (event.length > 1) {
              let durationItem = []
              for (let item of event) {
                durationItem.push(duration(item.start, item.end))
              }
              object[dayjs(event[0].start).format("HH:mm")] = contructEvent(event, durationItem, true)
            } else {
              for (const item of event) {
                let durationItem = duration(item.start, item.end)
                object[dayjs(item.start).format("HH:mm")] = contructEvent(item, durationItem, false)
              }
            }
          }
        } else {
          for (const item of event) {
            let durationItem = duration(item.start, item.end)
            object[dayjs(item.start).format("HH:mm")] = contructEvent(item, durationItem, false)
          }
        }
        dataArray.push(object)
      }
    }
    return dataArray
  }

  return (
    <>
      <Row justify='space-between'>
        <Col>
          <div className="legendSchedulerTable">
            <p><span className="badge-event badge-overbooking"></span> Overbooking</p>
            <p><span className="badge-event badge-price"></span> Pendiente pago</p>
            <p><span className="badge-event badge-notes"></span> Anotaciones</p>
            <p><span className="badge-event badge-vip"></span> VIP</p>
          </div>
        </Col>
        <Col>
        </Col>
        <Col style={{display: 'flex', alignItems: 'flex-start', gap: 10}}>
          <Form form={form} style={{display: 'flex', gap: 10}}>
            <Form.Item name="client">
              <Input
                placeholder="Buscador cliente"
                style={{ width: 150 }}
                //onChange={(a) => console.log(a.target.value, form.getFieldValue("activity"))}
                onPressEnter={(a) => {
                  form.getFieldValue("activity") !== null ?
                    props.filterTable(props.families.find(e => e.value === form.getFieldValue("activity")), a.target.value)
                  : props.filterTable(null, a.target.value)
                }}
              />
            </Form.Item>
            <Form.Item name="activity">
              <Select
                style={{ width: 200 }}
                placeholder="Selecciona una actividad"
                onChange={(e, a) => props.filterTable(a, form.getFieldValue("client"))}
                options={props.families}
                value={props.familySelected !== null ? props.familySelected : null}
                //disabled={props.data.events.length < 1}
              />
            </Form.Item>
          </Form>
          <Tooltip title="Resetear selección">
            <Button
              disabled={props.familySelected !== null || props.textFilter !== null ? false : true}
              onClick={() => props.filterTable(null, null)}
            ><Refresh /></Button>
          </Tooltip>
        </Col>
      </Row>
      <div className="syncScrollsTop" style={{overflow: "auto"}} onScroll={(e) => scrollSlave(e)}><div className="scrollTableTop" style={{height: 1}}></div></div>
      <div className="syncScrollsTable" style={{overflow: "auto"}} onScroll={(e) => scrollMaster(e)}>
        <Table
          ref={tableRef}
          className={`schedulerTable${props.data.events.length > 0 ? "" : " emptyschedulerTable"}`}
          showHeader={props.data.events.length > 0 ? true : false}
          bordered
          columns={arrayTime}
          dataSource={prepareData()}
          style={{backgroundColor: "#FFF", borderRadius: 8}}
          pagination={false}
          locale={{ emptyText: (
            <>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={<span>No hay reservas en este día para<br />la família de actividad seleccionada</span>}
              />
            </>
            )
          }}
        />
      </div>
    </>
  )
}