import React from "react";
import { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Select, Input, Tabs, Steps, Button, notification } from "antd";
import { EditOutlined, CopyOutlined } from '@ant-design/icons';
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import apiManager from "../../api/apiManager";
import ActivityForm from "../Actividades/ActivityForm";
import BookingCreateTab from "./BookingCreateTab";
import BookingResume from "./BookingResume";

import { useUser } from "../../api/context/userContext";

//confirm dialog
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css';

import "./bookingcreate.css";

import 'react-big-calendar/lib/css/react-big-calendar.css';

const localizer = momentLocalizer(moment);
require("moment/locale/es.js");

export default function BookingCreate(props) {

  const { userData } = useUser()
  const { TabPane } = Tabs;

  const [compActivity, setCompActivity] = useState("")
  const [globalActivities, setGlobalActivities] = useState([])

  const [events, setEvents] = useState([])
  const [channels, setChannels] = useState([])
  const [optionsDates, setOptionsDates] = useState([])
  const [pdOneComplete, setPdOneComplete] = useState(false)
  const [pdTwoComplete, setPdTwoComplete] = useState(true)
  const [currentStep, setCurrentStep] = useState(0)
  const [activeState,setActiveState] = useState('0')
  const [emailRequired, setEmailRequired] = useState(false)
  const [dateStep, setDateStep] = useState(null)
  const [panes,setPanes] = useState([])
  const [continueBtn, setContinueBtn] = useState(true)
  const [disableContinue, setDisableContinue] = useState(true)
  const [validationsStatus, setValidationsStatus] = useState(false)
  const [form] = Form.useForm()
  const tabsRef = useRef()

  useEffect(() => {
    form.setFieldValue('channel', null)
    form.setFieldValue('name', '')
    form.setFieldValue('surname', '')
    form.setFieldValue('phone', '')
    form.setFieldValue('mail', '')
    apiManager.getChannels(
      JSON.parse(localStorage.getItem("activeCenter")).id,
      userData.is_b2b ? userData.is_b2b : undefined,
      userData.is_b2b ? userData.user_token : undefined
    )
    .then((res) => {
      res.data.data.map((channel) => {
        setChannels(current => [...current, {
          'value': channel.id,
          'label': channel.title,
          'email': channel.email_required
        }])
        if (userData.is_b2b) {
          form.setFieldValue("channel", res.data.data[0].id)
        }
      })
    })
  }, [])

  useEffect(() => {
    let arrayDays = []
    optionsDates.map((tag) => {
      arrayDays.push(tag.value)
    })
    form.setFieldValue('dates', arrayDays)
    updatedForm(form.getFieldValue('channel'))
  }, [optionsDates])

  useEffect(() => {
    //console.log(globalActivities)
  }, [globalActivities])

  function periodEditB2B(start) {
    let today = new Date()
    today.setHours(0,0,0,0)
    let startDate = start
    startDate.setHours(0,0,0,0)
    let diff = startDate.getTime() - today.getTime()
    let dayDiff = diff / (1000 * 60 * 60 * 24)
    return dayDiff
  }

  const selectedDate = (date) => {
    if (userData.is_b2b) {
      let dateStart = date.start
      let dateEnd = date.end
      let diff = dateEnd.getTime() - dateStart.getTime()
      var daydiff = diff / (1000 * 60 * 60 * 24)
      if (daydiff > 1) {
        notification.warning({
          key: 'warning',
          placement: 'bottom',
          duration: 8,
          message: 'No se permite añadir más de una fecha'
        })
      }
      let dataMoment = moment(date.end).subtract(1, 'd')
      date.start.setDate(dataMoment.toDate().getDate())
      if (!(periodEditB2B(date.start) > Number(userData.edition_period))) {
        resetDate()
        notification.warning({
          key: 'warning',
          placement: 'bottom',
          duration: 8,
          message: `No se permite crear una reserva con fecha anterior a la actual${Number(userData.edition_period) <= 0 ? '' : Number(userData.edition_period) === 1 ? ' más 1 día' : ` más ${Number(userData.edition_period)} días`}`
        })
      } else {
        setPanes([])
        setEvents([{
          title: '',
          start: date.start,
          end: date.end,
          allDay: true
        }])
        getDates(date.start, date.end)
      }
    } else {
      setPanes([])
      setEvents([{
        title: '',
        start: date.start,
        end: date.end,
        allDay: true
      }])
      getDates(date.start, date.end)
    }
  }

  Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  }

  function getDates(startDate, stopDate) {
    setOptionsDates([]);
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date (currentDate));
      currentDate = currentDate.addDays(1);
    }
    dateArray.map((day, index) => {
      if (index === 0) {
        setDateStep(moment(day).format('DD-MM-YYYY'))
      }
      if ((index + 1) < dateArray.length) {
        setOptionsDates(current => [...current, {
          'value': moment(day).format('DD-MM-YYYY'),
          'label': moment(day).format('DD-MM-YYYY'),
          'index': index
        }])
      }
    })
  }

  const addTabPanes = () => {
    //console.log(optionsDates)
    optionsDates.map((tab, index) => {
      setPanes((prev) => [
        ...prev,
        {
          title: tab.value,
          key: index
        }
      ]);
      activityFormComp(tab.value, form.getFieldValue('channel'), index)
    })
    if (optionsDates.length > 0) {
      setPdOneComplete(true)
      setPdTwoComplete(true)
    }
  }

  const updatedForm = (value) => {
    let requiredEmail = false
    let requiredPhone = userData.is_b2b
    let validations = false
    if (value !== null && channels.some(x => x.email === true)) {
      if (userData.is_b2b) {
        requiredEmail = true
        setEmailRequired(true)
      } else {
        requiredEmail = channels.find(x => x.value === value).email
        setEmailRequired(channels.find(x => x.value === value).email)
      }
    } else {
      requiredEmail = userData.is_b2b
      setEmailRequired(userData.is_b2b)
    }
    if (requiredEmail) {
      validations =
        form.getFieldValue('name') !== '' &&
        form.getFieldValue('surname') !== '' &&
        (!userData.is_b2b || (requiredPhone && form.getFieldValue('phone') !== '')) &&
        form.getFieldValue('channel') !== null &&
        ValidateEmail(form.getFieldValue('mail'))
        setValidationsStatus(
          form.getFieldValue('name') !== '' &&
          form.getFieldValue('surname') !== '' &&
          (!userData.is_b2b || (requiredPhone && form.getFieldValue('phone') !== '')) &&
          form.getFieldValue('channel') !== null &&
          ValidateEmail(form.getFieldValue('mail'))
        )
    } else if(requiredPhone) {
      validations =
        form.getFieldValue('name') !== '' &&
        form.getFieldValue('surname') !== '' &&
        form.getFieldValue('phone') !== '' &&
        form.getFieldValue('channel') !== null
      setValidationsStatus(
        form.getFieldValue('name') !== '' &&
        form.getFieldValue('surname') !== '' &&
        form.getFieldValue('phone') !== '' &&
        form.getFieldValue('channel') !== null
      )
    } else {
      validations =
        form.getFieldValue('name') !== '' &&
        form.getFieldValue('surname') !== '' &&
        form.getFieldValue('channel') !== null
      setValidationsStatus(
        form.getFieldValue('name') !== '' &&
        form.getFieldValue('surname') !== '' &&
        form.getFieldValue('channel') !== null
      )
    }
    if (
      /* form.getFieldValue('name') !== '' &&
      form.getFieldValue('surname') !== '' &&
      form.getFieldValue('channel') !== null */
      validations
      //form.getFieldValue('dates').length > 0
    ) {
      if (!pdOneComplete) {
        setPdTwoComplete(false)
        addTabPanes()
      }
      setDisableContinue(false)
    } else {
      setPdTwoComplete(true)
      setDisableContinue(true)
    }
  }

  function ValidateEmail(inputText) {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if(inputText.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }

  const alterLabel = (index) => {
    let items = optionsDates
    let item = items[index]
    setDateStep(item.value)
    //console.log(item)
  }

  const activityFormComp = (title, channel, key) => {
    setCompActivity(
      <ActivityForm
        selectedDay={title}
        selectedChannel={channel}
        dateStep={dateStep}
        tabKey={key}
        alterLabel={alterLabel}
        userData={userData}
      />
    )
  }

  const saveGlobalActivities = (value, index) => {
    if (value.length > 0) {
      if (globalActivities.find(o => o.date === index) === undefined) {
        setGlobalActivities(current => [...current,
          {
            date: index,
            activities: value
          }
        ])
      } else {
        updateGlobalActivities(value, index)
      }
    }
  }

  const updateGlobalActivities = (value, date) => {
    const newState = globalActivities.map((obj, index) => {
      if (obj.date === date) {
        return {...obj, activities: value}
      }
      return obj
    })
    setGlobalActivities(newState)
    toggleContinueBtn(true)
  }

  const cleanGlobalActivities = (date) => {
    setGlobalActivities(globalActivities.filter(a => a.date !== date))
  }

  const toggleContinueBtn = (value) => {
    setContinueBtn(value)
  }

  const goToResume = () => {
    if (form.getFieldValue("name") === "") {
      notification.warning({
        key: 'warning',
        placement: 'bottom',
        duration: 8,
        message: 'El campo Nombre es obligatorio'
      })
    } else if (form.getFieldValue("surname") === "") {
      notification.warning({
        key: 'warning',
        placement: 'bottom',
        duration: 8,
        message: 'El campo Apellidos es obligatorio'
      })
    } else {
      setCurrentStep(currentStep + 1)
    }
  }

  const goBackToForm = () => {
    setCurrentStep(0)
  }

  const resetDate = () => {
    setEvents([])
    setOptionsDates([])
    setDateStep(null)
    setPanes([])
    form.setFieldValue('dates', undefined)
    setGlobalActivities([])
    setPdOneComplete(false)
    setPdTwoComplete(false)
  }

  const confirmBackToDate = (value) => {
    let textOne = ''
    let textTwo = ''
    let textTree = ''
    if (value === 'date') {
      textOne = '¿Quieres elegir una nueva fecha?'
      textTwo = 'Seleccionar nueva fecha'
      textTree = 'Mantener fecha actual'
    } else if (value === 'channel') {
      textOne = '¿Quieres elegir un nuevo canal?'
      textTwo = 'Seleccionar nuevo canal'
      textTree = 'Cancelar'
    }
    confirmAlert({
      title: textOne,
      message: 'Se perderán todos los datos sobre la actividad introducidos hasta ahora',
      buttons: [
        {
          label: textTwo,
          onClick: () => resetDate(),
          className: 'confirm-btn',
        },
        {
          label: textTree,
          className: 'cancel-btn',
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true,
      overlayClassName: "overlay-confirm-suprimir"
    })
  }

  const closeFinishBooking = (date, channel, id) => {
    props.closeFinishBooking(date, channel, id)
  }

  const checkPdOneItems = () => {
    form.validateFields()
  }

  return (
    <>
      {/* {
        globalActivities.map((date) =>
          <>
            <p>HOLA</p> 
            <p>{date.date}</p>
            {
              date.activities.map((a) => 
                <p>{ a.selectedTurn }</p>
              )
            }
          </>
        )
      } */}
      <Row justify="center">
        <Col span={14} style={{marginBottom: 30, marginTop: 20}}>
          <Steps 
            className="stepsForm"
            size="small"
            current={currentStep}
            labelPlacement="vertical"
            items={[{icon: <EditOutlined />}, {icon: <CopyOutlined />}]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{display: currentStep > 0 ? 'none' : 'block'}}>
          <Form
            layout="vertical"
            className="bookingForm"
            form={form}
          >
            <Row>
              <Col span={12}>
                <Form.Item
                  label="Canal:"
                  name="channel"
                  rules={
                    [{
                      required: true,
                      message: "Este campo es obligatorio",
                    }]
                  }
                >
                  <Select
                    showSearch
                    style={{ width: '500px' }}
                    placeholder="Selecciona un canal"
                    options={channels}
                    onChange={(e) => updatedForm(e)}
                    disabled={pdOneComplete || userData.is_b2b ? true : false}
                    onClick={() => pdOneComplete && !userData.is_b2b ? confirmBackToDate('channel') : ""}
                    filterOption={(input, option) =>
                      (option.label).toLowerCase().includes(input.toLowerCase())
                    }
                  />
                </Form.Item>
                <Form.Item
                  label="Nombre:"
                  name="name"
                  rules={
                    [{
                      required: true,
                      message: "Este campo es obligatorio",
                    }]
                  }
                >
                  <Input
                    style={{ width: '500px' }}
                    placeholder="Introduce un nombre"
                    onChange={() => updatedForm(form.getFieldValue('channel'))}
                  />
                </Form.Item>
                <Form.Item
                  label="Apellidos:"
                  name="surname"
                  rules={
                    [{
                      required: true,
                      message: "Este campo es obligatorio",
                    }]
                  }
                >
                  <Input
                    style={{ width: '500px' }}
                    placeholder="Introduce unos apellidos"
                    onChange={() => updatedForm(form.getFieldValue('channel'))}
                  />
                </Form.Item>
                <Form.Item
                  label="Teléfono:"
                  name="phone"
                  rules={
                    userData.is_b2b ?
                      [{
                        required: true,
                        message: "Este campo es obligatorio",
                      }]
                    :
                      []
                  }
                >
                  <Input
                    style={{ width: '500px' }}
                    placeholder="Introduce un teléfono"
                    onChange={() => userData.is_b2b ? updatedForm(form.getFieldValue('channel')) : ""}
                  />
                </Form.Item>
                <Form.Item
                  label="Correo electrónico:"
                  name="mail"
                  rules={
                    emailRequired ?
                      [{
                        type: "email",
                        message: "Formato de email no válido!",
                      },
                      {
                        required: true,
                        message: "Este campo es obligatorio",
                      }]
                    :
                      [{
                        type: "email",
                        message: "Formato de email no válido!",
                      }]
                  }
                >
                  <Input
                    style={{ width: '500px' }}
                    placeholder="Introduce un correo electrónico"
                    onChange={() => emailRequired ? updatedForm(form.getFieldValue('channel')) : ""}
                  />
                </Form.Item>
                <Form.Item
                  label="DNI:"
                  name="dni"
                >
                  <Input
                    style={{ width: '500px' }}
                    placeholder="Introduce un DNI"
                  />
                </Form.Item>
                <Form.Item
                  label="Referencia interna:"
                  name="refInt"
                >
                  <Input
                    style={{ width: '500px' }}
                    placeholder="Introduce la referencia interna"
                  />
                </Form.Item>
              </Col>
              <Col className="calendarCol" span={12}>
                {
                  pdTwoComplete ? <div className="calendarOverlay" onClick={() => 
                      validationsStatus ?
                        confirmBackToDate('date')
                      : checkPdOneItems()
                  }></div> : ""
                }
                <Form.Item
                  name="calendar"
                >
                  <div style={{ height: "300px" }} className={`bigCalendar-container `}>
                    <Calendar
                      className="calendarField"
                      selectable
                      localizer={localizer}
                      events={events}
                      messages={{
                        next: ">",
                        today: "Mes actual",
                        previous: "<",
                        month: "Mes",
                        week: "Semana",
                        day: "Día",
                      }}
                      views={["month"]}
                      //getNow={() => moment("20111020")}
                      getNow={() => moment()}
                      onSelectSlot={(date) => selectedDate(date)}
                    />
                  </div>
                </Form.Item>
                <Form.Item
                  name="dates"
                >
                  <Select
                    mode="multiple"
                    className="selectedDates"
                    disabled
                    bordered={false}
                    options={optionsDates}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col span={24}>
              { panes.length > 0 ?
                <Tabs
                  //onChange={onChange}
                  //activeKey={activeState}
                  //onEdit={onEdit}
                  className="dateTabs"
                  //ref={tabsRef}
                  //type="editable-card"
                  //hideAdd={true}
                  centered={true}
                  onChange={(e) => alterLabel(e)}
                  style={{marginTop: "20px"}}
                >
                  { panes.map(pane => (
                    <TabPane
                      tab={pane.title}
                      key={pane.key}
                      ref={tabsRef}
                      closeIcon={<></>}
                    >
                      <BookingCreateTab 
                        selectedDay={pane.title}
                        selectedChannel={form.getFieldValue('channel')}
                        dateStep={dateStep}
                        tabKey={pane.key}
                        alterLabel={alterLabel}
                        saveGlobalActivities={saveGlobalActivities}
                        cleanGlobalActivities={cleanGlobalActivities}
                        toggleContinueBtn={toggleContinueBtn}
                      />
                    </TabPane>
                  ))}
                </Tabs>
                : ""
              }
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{display: currentStep > 0 ? 'block' : 'none'}}>
          <BookingResume
            data={globalActivities}
            personal={form.getFieldsValue()}
            closeFinishBooking={closeFinishBooking}
          />
        </Col>
        {
          globalActivities.length > 0 &&
          continueBtn &&
          currentStep === 0 ?
            <Col span={24} style={{textAlign: "center", marginTop: 30}}>
              <Button
                className="basic-btn accept-btn"
                onClick={() => goToResume()}
                disabled={ disableContinue }
              >Continuar</Button>
            </Col>
          : ""
        }
        {
          currentStep > 0 ?
            <Col span={24} style={{textAlign: "center", marginTop: 30}}>
              <Button className="basic-btn" onClick={() => goBackToForm()}>Volver</Button>
              {/* <Button className="basic-btn accept-btn" style={{marginLeft: 15}}>Finalizar</Button> */}
            </Col>
          : ""
        }
      </Row>
    </>
  )
}